import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowsWhite, LinesWhite, StudioDescriptionImg } from "../../../assets";

const svgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
  exit: { opacity: 0 },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.5,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    pathLength: 0,
  },
};

const StudioClipArtRight = () => {
  const [logo, setLogo] = useState(false);
  const [widt, setWidt] = useState(0);
  const [heit, setHeit] = useState(0);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec5Top = document
      .getElementById("StudioSec2")
      .getBoundingClientRect().top;
    if (HomeSec5Top > windowHeight - 350) {
      setLogo(false);
    } else {
      setLogo(true);
    }
  };

  const handleResize = () => {
    setWidt(document.getElementById("picture").offsetWidth * 1);
    setHeit(document.getElementById("picture").offsetHeight * 1);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <StudioArtContainer id="StudioSec2">
      <InfoContainer>
        <ArrowWrapper>
          <ArrowImg src={ArrowsWhite} />
          <LinesImg src={LinesWhite} />
        </ArrowWrapper>
        <ImageWrapper>
          <AnimatePresence>
            {logo && (
              <CustomSvg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                variants={svgVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.path
                  variants={pathVariants}
                  d={`M0 0 L${widt} 0 L${widt} ${heit} L0 ${heit} L0 -1Z`}
                  fill="none"
                />
              </CustomSvg>
            )}
          </AnimatePresence>
          <CustomImg
            onLoad={handleResize}
            id="picture"
            src={StudioDescriptionImg}
          />
        </ImageWrapper>
        <InfoWrapper>
          <TitleText>
            THE UNIVERSE OF PROJECT J
          </TitleText>
          <DescText>
          Project J is an immersive video game that offers players an enthralling experience through a modern world where supernatural beings are not just creatures of myth and folklore, but an ominous reality.
          </DescText>
        </InfoWrapper>
      </InfoContainer>
    </StudioArtContainer>
  );
};

const StudioArtContainer = styled.div`
  color: var(--text-1-500);
  z-index: 10;
  height: 100%;
  padding-top: 150px;
  position: relative;
  transition: opacity 0.5s;
  padding-bottom: 150px;
  @media (max-width: 1100px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media (max-width: 730px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const CustomSvg = styled(motion.svg)`
  width: 100px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
  stroke: var(--text-1-500);
  stroke-width: 2;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const LinesImg = styled.img`
  width: 30px;
  rotate: -90deg;
  position: absolute;
  left: -10px;
  top: -300px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 300px;
  top: 0;
  width: 1300px;
  height: 520px;
  @media (max-width: 1780px) {
    width: 1100px;
    height: 320px;
  }
  @media (max-width: 1350px) {
    width: 900px;
  }
  @media (max-width: 1150px) {
    width: 800px;
  }
  @media (max-width: 1070px) {
    width: 700px;
    height: 270px;
    margin-top: 210px;
  }
  @media (max-width: 930px) {
    display: none;
  }
`;

const ArrowImg = styled.img`
  position: absolute;
  right: -50px;
  width: 55px;
  bottom: 40px;
`;

const CustomImg = styled.img`
  width: 700px;
  @media (max-width: 1780px) {
    width: 500px;
  }
  @media (max-width: 1070px) {
    width: 300px;
  }
  @media (max-width: 600px) {
    width: 250px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 100px;
  align-items: center;
  padding: 30px;
  object-fit: cover;
  justify-content: center;
  @media (max-width: 1350px) {
    gap: 50px;
  }
  @media (max-width: 1160px) {
    flex-direction: column;
    text-align: center;
  }
  @media (max-width: 600px) {
    padding: 5px;
  }
`;

const InfoWrapper = styled.div`
  color: var(--text-1-500);
  width: 100%;
  max-width: 700px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1350px) {
    justify-content: center;
    max-width: 500px;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  font-family: "Rubik";
  font-weight: 700;
  @media (max-width: 1780px) {
    font-size: 34px;
  }
  @media (max-width: 1350px) {
    font-size: 30px;
  }
  @media (max-width: 1230px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DescText = styled.p`
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 1780px) {
    font-size: 17px;
  }
  @media (max-width: 1350px) {
    font-size: 15px;
  }
  @media (max-width: 1230px) {
    font-size: 12px;
  }
`;

export default StudioClipArtRight;
