import React, { useRef } from "react";
import styled from "styled-components";
import SwiperCore, { Virtual, Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Student1, Student2, Student3, Student4 } from "../../assets";

SwiperCore.use([Virtual, Navigation, Pagination, Autoplay]);

const imgSlide = [Student1,Student2, Student3, Student4];

const textSlide = [
  {
    title:
      "During my Unreal Engine 5 course, I learnt how to work with animation blueprints, blueprints, and animation sequences. Moreover, how to generally bring your ideas to life.",
    name: "Maksym Rasporskyi",
    position: "Game Developer",
  },
  {
    title:
      "This course was an amazing learning opportunity for me. I gained insightful knowledge from the training, and it assisted me in developing the skills required for the growth process.",
    name: "Igor Bocharov",
    position: "Game Developer | Sound Designer",
  },
  {
    title:
      "The experience of going through the Unreal Engine 5 learning course is truly amazing, and it left me with a new-found appreciation for the art of game development.",
    name: "Alexey Bobylev",
    position: "Game Developer",
  },
  {
    title:
      "I had a fantastic opportunity to explore the virtually endless possibilities of game development thanks to the Unreal Engine 5 study course.",
    name: "Kazem Abusitta",
    position: "Game Developer | Level Designer",
  },
];

export default function SlideShow() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  // Create array with 500 slides

  return (
    <>
      <SlideContainer>
        <Swiper
          style={{ height: "auto" }}
          speed={1500}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          slidesPerView={1}
          allowTouchMove={false}
          loop={true}
          spaceBetween={30}
        >
          {textSlide.map(({ title, name, position }, idx) => (
            <CustomSwiperSlide style={{ background: "none" }} key={idx}>
              <TextWrapper>
                <p style={{ fontFamily: "Space Mono", lineHeight: "1.5" }}>{title}</p>
                <NameInfoWrapper>
                  <h1 style={{ fontFamily: "Rubik", fontWeight: 700,textTransform:"uppercase" }}>
                    {name}
                  </h1>
                  <h2 style={{ fontFamily: "Space Mono", color: "#7c7c7c" }}>{position}</h2>
                </NameInfoWrapper>
              </TextWrapper>
            </CustomSwiperSlide>
          ))}
        </Swiper>
        <ButtonWrapper>
          <CustomButton
            className="swiper-button-prev"
            ref={navigationPrevRef}
          ></CustomButton>
          <CustomButton
            className="swiper-button-next"
            ref={navigationNextRef}
          ></CustomButton>
        </ButtonWrapper>
      </SlideContainer>
      <Swiper
        style={{ height: "auto" }}
        allowTouchMove={false}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        speed={1500}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={30}
      >
        {imgSlide.map((slideContent) => (
          <CustomSwiperSlidePic key={slideContent}>
            <img src={slideContent} alt="slider images" />
          </CustomSwiperSlidePic>
        ))}
      </Swiper>
    </>
  );
}

const NameInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CustomButton = styled.div`
  color: var(--text-1-500);
  &.swiper-button-next {
    right: 10px !important;
    display: block !important;
  }

  &.swiper-button-prev {
    left: 10px !important;
    display: block !important;
  }

  &.swiper-button-next::after {
    font-size: 25px;
  }

  &.swiper-button-prev::after {
    font-size: 25px;
  }
`;

const CustomSwiperSlidePic = styled(SwiperSlide)`
  & img {
    transition: all 1.5s;
    opacity: 0.5;
  }

  background: var(--main-600);
  &.swiper-slide-prev img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(2px);
  }
  &.swiper-slide-active img {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-next img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(2px);
  }
  &.swiper-slide-duplicate-prev img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(2px);
  }
  &.swiper-slide-duplicate-active img {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-duplicate-next img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(2px);
  }
`;

const CustomSwiperSlide = styled(SwiperSlide)`
  transition: opacity 1.5s;
  &.swiper-slide-prev {
    opacity: 0;
  }
  &.swiper-slide-active {
    opacity: 1;
  }
  &.swiper-slide-next {
    opacity: 0;
  }
`;

const SlideContainer = styled.div`
  width: 100%;
  padding: 30px 0px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  width: 700px;
  position: absolute;
  top: 100px;
  @media (max-width: 745px) {
    width: 350px;
    top: 110px;
  }
`;

const TextWrapper = styled.div`
  background: none;
  color: var(--text-1-500);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  @media (max-width: 745px) {
    max-width: 220px;
    font-size: 13px;
  }
`;
