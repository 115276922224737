import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SendEmail } from "../../components/sendEmail";
import { TrainingParallaxBanner } from "../../components/parallaxBanner/trainingParallax";
import TrainingInfo from "../../components/trainingInfo";
import SlideShow from "../../components/slideShow";

const TrainingPage = ({ setSelected }) => {
  const [display, setDisplay] = useState(false);
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const TrainingSec2Top = document
      .getElementById("TrainingSec2")
      .getBoundingClientRect().top;
    if (TrainingSec2Top > windowHeight - 150) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };
  useEffect(() => {
    setSelected(3);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div style={{overflow: "hidden"}}>
      <TrainingParallaxBanner />
      <TitleWrapper style={{ opacity: display ? 1 : 0 }} id="TrainingSec2">
        <TitleText>
        Unleash Your Creativity with Unreal Engine 5
        </TitleText>
      </TitleWrapper>
      <TrainingInfo />
      <SlideShow />
      <SendEmail />
    </div>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px 100px 0px;
  @media (max-width: 767px)
  {
	padding: 0px 20px 100px 0px;
  }
  transition: all 0.5s;
  transition-delay: 0.5s;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const TitleText = styled.h1`
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  max-width: 800px;
  color: var(--text-1-500);
  font-family: 'Rubik';
  font-weight: 700;
  @media (max-width: 600px) {
    padding: 0;
    font-size: 20px;
  }
`;

export default TrainingPage;
