import React, { useEffect, useState } from "react";
import styled from "styled-components";

var timeout;
const DrawLine = ({ display, size, pos, id, display2 }) => {
  const [animLen, setAnimLen] = useState({ len: 0, anim: true });

  useEffect(() => {
    if (display) {
      clearTimeout(timeout);
      setAnimLen({ len: 0, anim: true });
    } else {
      var element = document.getElementById(id);
      var pathLength = element.getTotalLength();
      element.style.strokeDasharray = pathLength;
      if (id === "animpath") {
        timeout = setTimeout(() => {
          setAnimLen({ len: pathLength, anim: false });
        }, 1000);
      } else {
        setAnimLen({ len: pathLength, anim: true });
      }
    }
  }, [display, id]);

  useEffect(() => {
    if (display2) {
      setAnimLen({ len: 0, anim: false });
    }
  }, [display2]);
  return (
    <CustomSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <CustomPath
        style={{
          strokeDashoffset: animLen.len,
          transition: animLen.anim ? "all 0.7s" : "none",
        }}
        d={`M${pos} 0 L${size} 0`}
        id={id}
      />
    </CustomSvg>
  );
};

const CustomPath = styled.path`
  fill: none;
  stroke: var(--text-1-500);
  stroke-width: 6;
  stroke-linejoin: round;
  stroke-linecap: round;
`;

const CustomSvg = styled.svg`
  width: 80px;
  position: absolute;
  right: calc(50% - 0.5px);
  margin-left: 10px;
  top: 50px;
  rotate: 90deg;
  overflow: visible;
  @media (max-width: 1011px) {
    left: -29.5px;
    top: 0px;
  }
`;

export default DrawLine;
