import React, { useEffect } from "react";
import { StudioParallaxBanner } from "../../components/parallaxBanner/studioParallax";
import StudioClipArtRight from "../../components/studioClipArt/studioClipArtRight";
import StudioEnemies from "../../components/studioEnemies";
import StudioLargeCarousel from "../../components/studioLargeCarousel";
import StudioNomad from "../../components/studioNomad";

const StudioPage = ({ setSelected }) => {
  useEffect(() => {
    setSelected(2);
  });

  return (
    <div style={{overflow: "hidden"}}>
		<StudioParallaxBanner />
		<StudioClipArtRight />
		<StudioNomad />
		<StudioEnemies />
		{/* <StudioCarousel /> */}
		<StudioLargeCarousel />
    </div>
  );
};

export default StudioPage;
