import React, { useEffect } from "react";

const PageNotFound = ({ setSelected }) => {
  useEffect(() => {
    setSelected(0);
  }, [setSelected]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h1 style={{ fontSize: "50px", color: "#fff" }}>404 PAGE NOT FOUND</h1>
    </div>
  );
};

export default PageNotFound;
