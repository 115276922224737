import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowsBlack, LinesBlack } from "../../assets";

const HomeTraining = ({ title, description, img, section, btn }) => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec3Top = document
      .getElementById(btn ? "HomeSec6" : "HomeSec3")
      .getBoundingClientRect().top;
    if (HomeSec3Top <= windowHeight - 200) {
      setLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <>
      <GameDev id={btn ? "HomeSec6" : "HomeSec3"}>
        <UE5Text
          style={{
            top: logo ? "-40px" : "20%",
            opacity: logo ? 1 : 0,
          }}
        >
          Unreal Engine 5
        </UE5Text>
        <DoubleText
          style={{
            zIndex: 9,
            WebkitTextStroke: "1px rgb(255, 255, 255)",
            bottom: logo ? "40px" : "-20%",
            opacity: logo ? 1 : 0,
          }}
        >
          {section}
        </DoubleText>
      </GameDev>
      <ClipPath>
        <GameDev>
          <DoubleText
            style={{
              WebkitTextStroke: "1px rgb(0, 0, 0)",
              bottom: logo ? "40px" : "-20%",
              opacity: logo ? 1 : 0,
            }}
          >
            {section}
          </DoubleText>
        </GameDev>
        <InfoContainer>
          <ArrowWrapper>
            <ArrowImg btn={btn} src={ArrowsBlack} />
            {btn && <LinesImgTop src={LinesBlack}></LinesImgTop>}
          </ArrowWrapper>
          <InfoWrapper>
            <TitleText>{title}</TitleText>
            <DescText>{description}</DescText>
            {btn && (
              <Link style={{ textDecoration: "none" }} to="/training">
                <CustomButton>UE5 Training</CustomButton>
              </Link>
            )}
            {!btn && <LinesImg src={LinesBlack}></LinesImg>}
          </InfoWrapper>
          <CustomImg src={img} />
        </InfoContainer>
      </ClipPath>
    </>
  );
};

const UE5Text = styled.h1`
  transition: all 0.7s;
  font-size: 80px;
  text-transform: uppercase;
  font-family: Rubik;
  font-weight: 700;
  z-index: 90;
  color: var(--text-1-500);
  position: absolute;
  @media (max-width: 1500px) {
    font-size: 70px;
  }
  @media (max-width: 1024px) {
    font-size: 50px;
  }
  @media (max-width: 767px) {
    font-size: 35px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 400px;
  width: 1300px;
  top: 0;
  height: 380px;
  @media (max-width: 1300px) {
    display: none;
  }
  @media (max-width: 1500px) {
    margin-top: 300px;
  }
`;

const LinesImgTop = styled.img`
  width: 30px;
  rotate: -90deg;
  position: absolute;
  left: 80px;
  top: -100px;
`;

const ArrowImg = styled.img`
  position: absolute;
  right: 50px;
  rotate: ${(props) => (props.btn ? "0" : "180deg")};
  ${({ btn }) => (btn ? { bottom: "70px" } : { top: "0px" })}
  width: 20px;
`;

const LinesImg = styled.img`
  width: 30px;
  rotate: -90deg;
  bottom: -130px;
  margin-left: 60px;
  position: absolute;
  text-align: center;
  @media (max-width: 1100px) {
    margin-left: 0;
  }
  @media (max-width: 1300px) {
    display: none;
  }
`;

const CustomButton = styled.button`
  color: var(--text-1-500);
  background-color: transparent;
  border: 1px solid var(--text-1-500);
  width: 170px;
  padding: 10px 20px 12px calc(20px - 0.02em);
  justify-content: center;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.02em;
  font-family: Rubik, sans-serif;
  font-weight: 700;
  line-height: calc(30 / 17);
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  mix-blend-mode: difference;
  margin: 0;
  isolation: isolate;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  &:hover::before {
    clip-path: inset(0 0 0 0);
  }
  &::after {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: 5px;
    background-color: currentColor;
    position: absolute;
    bottom: 3px;
    left: 3px;
    pointer-events: none;
  }
  &::before {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px - 4px);
    background-color: currentColor;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96),
      -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    pointer-events: none;
    mix-blend-mode: difference;
    -webkit-clip-path: inset(100% 0 0 0);
    clip-path: inset(100% 0 0 0);
  }
`;

const CustomImg = styled.img`
  width: 600px;

  @media (max-width: 1100px) {
    width: 300px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  background-color: var(--main-2-500);
  width: 100%;
  transform: translateZ(0);
  height: 100%;
  padding: 400px 50px 100px 50px;
  align-items: center;
  position: relative;
  @media (max-width: 1100px) {
    flex-direction: column;
    text-align: center;
  }
  @media (max-width: 1500px) {
    padding: 300px 50px 90px 50px;
  }
  @media (max-width: 1024px) {
    padding: 230px 0px 40px 0px;
  }
  @media (max-width: 767px) {
    padding: 140px 0px 10px 0px;
  }
  @media (max-width: 500px) {
    padding: 100px 0px 20px 0px;
  }
  justify-content: center;
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 700px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  font-family: Rubik;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 1100px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DescText = styled.p`
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 1100px) {
    font-size: 15px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const GameDev = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 350px;
  @media (max-width: 1500px) {
    height: 280px;
  }
  @media (max-width: 1024px) {
    height: 210px;
  }
  @media (max-width: 767px) {
    height: 160px;
  }
  @media (max-width: 500px) {
    height: 110px;
  }
`;

const DoubleText = styled.h2`
  transition: all 0.7s;
  font-size: 250px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  letter-spacing: 3px;
  font-weight: 700;
  position: absolute;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 180px;
  }
  @media (max-width: 1024px) {
    font-size: 130px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
  }
  @media (max-width: 500px) {
    font-size: 60px;
  }
`;

const ClipPath = styled.div`
  background: #eff4f3;
  z-index: 10;
  height: 100%;
  position: relative;
  clip-path: polygon(50% 250px, 100% 0, 100% 100%, 0 100%, 0 0);
  @media (max-width: 1500px) {
    clip-path: polygon(50% 200px, 100% 0, 100% 100%, 0 100%, 0 0);
  }
  @media (max-width: 1024px) {
    clip-path: polygon(50% 140px, 100% 0, 100% 100%, 0 100%, 0 0);
  }
  @media (max-width: 767px) {
    clip-path: polygon(50% 100px, 100% 0, 100% 100%, 0 100%, 0 0);
  }
  @media (max-width: 500px) {
    clip-path: polygon(50% 55px, 100% 0, 100% 100%, 0 100%, 0 0);
  }
`;

export default HomeTraining;
