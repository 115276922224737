import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const svgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
  exit: { opacity: 0 },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    pathLength: 0,
  },
};

const TrainingInfoPic = ({ title, description, img, left, display }) => {
  const [pathD, setPathD] = useState(
    "M0 0 L430 0 L495 60 L495 395 L0 395 L0 395Z"
  );

  const handleResize = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 1011) {
      setPathD("M0 0 L210 0 L250 40 L250 203 L0 203 L0 203Z");
    } else {
      setPathD("M0 0 L430 0 L495 60 L495 395 L0 395 L0 395Z");
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 1011) {
      setPathD("M0 0 L210 0 L250 40 L250 203 L0 203 L0 203Z");
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <InfoWrapper left={left}>
      {left ? (
        <InfoDiv>
          <InfoImg src={img} />
          <AnimatePresence>
            {display && (
              <CustomSvg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                variants={svgVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.path fill="none" d={pathD} variants={pathVariants} />
              </CustomSvg>
            )}
          </AnimatePresence>
        </InfoDiv>
      ) : (
        <FixLeft>
          <InfoTextWrapperLeft>
            <InfoTitle>{title}</InfoTitle>
            <InfoDesc>{description}</InfoDesc>
          </InfoTextWrapperLeft>
        </FixLeft>
      )}
      {left ? (
        <InfoTextWrapper>
          <InfoTitle>{title}</InfoTitle>
          <InfoDesc>{description}</InfoDesc>
        </InfoTextWrapper>
      ) : (
        <InfoDiv>
          <InfoImg src={img} />
          <AnimatePresence>
            {display && (
              <CustomSvg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                variants={svgVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <motion.path fill="none" d={pathD} variants={pathVariants} />
              </CustomSvg>
            )}
          </AnimatePresence>
        </InfoDiv>
      )}
    </InfoWrapper>
  );
};

const InfoDiv = styled.div`
  position: relative;
  width: 400px;
  height: 285px;
  @media (max-width: 1011px) {
    width: 200px;
    height: 142px;
  }
`;

const FixLeft = styled.div`
  width: 370px;
  position: relative;
  @media (max-width: 1011px) {
    width: auto;
  }
`;

const CustomSvg = styled(motion.svg)`
  width: 80px;
  position: absolute;
  left: 20px;
  top: -15px;
  overflow: visible;
  stroke: var(--text-1-500);
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
  @media (max-width: 1011px) {
    top: -10px;
    left: 10px;
  }
`;

const InfoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InfoTitle = styled.h1`
  text-transform: uppercase;
  font-size: 25px;
  font-family: "Rubik";
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

const InfoDesc = styled.p`
  font-size: 15px;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const InfoTextWrapperLeft = styled.div`
  width: 370px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--text-1-500);
  position: absolute;
  left: 40px;
  top: -40px;
  @media (max-width: 1011px) {
    position: static;
    width: 350px;
    padding-left: 20px;
  }
  @media (max-width: 767px) {
    width: 210px;
    padding-left: 10px;
  }
`;

const InfoTextWrapper = styled.div`
  width: 370px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--text-1-500);
  @media (max-width: 1011px) {
    width: 350px;
    padding-left: 20px;
  }
  @media (max-width: 767px) {
    width: 210px;
    padding-left: 10px;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 110px;
  margin-bottom: 70px;
  justify-content: center;
  @media (max-width: 1011px) {
    flex-direction: ${(props) => (props.left ? "column" : "column-reverse")};
    gap: 40px;
    margin-left: 40px;
  }
`;

export default TrainingInfoPic;
