import React from "react";
import ReactDOM from "react-dom/client";
import { ParallaxProvider } from "react-scroll-parallax";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/jost";
// import '../node_modules/bootstrap-icons/dist/css/bootstrap.min.css'
import "./index.scss";
import "./style.css";
import "../node_modules/swiper/swiper-bundle.min.css";
import "../node_modules/swiper/swiper.min.css";
import "../node_modules/swiper/modules/pagination/pagination.min.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ParallaxProvider>
      <App />
  </ParallaxProvider>
);
