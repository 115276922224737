import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GlobalStyle } from "./globalStyle/globalStyle";
import { Layout } from "./components/layout/layout";
import { useEffect, useState } from "react";
import HomePage from "./pages/homePage";
import TrainingPage from "./pages/trainingPage";
import NewsPage from "./pages/newsPage";
import SingleNewsPage from "./pages/singleNewsPage";
import EsportsPage from "./pages/esportsPage";
import StudioPage from "./pages/studioPage";
import PageNotFound from "./pages/pageNotFound";

const App = () => {
  const [selected, setSelected] = useState(1);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const router = createBrowserRouter([
    {
      element: (
        <Layout
          selected={selected}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      ),
      children: [
        { path: "/", element: <HomePage setSelected={setSelected} /> },
        { path: "studio", element: <StudioPage setSelected={setSelected} /> },
        {
          path: "training",
          element: <TrainingPage setSelected={setSelected} />,
        },
        { path: "esports", element: <EsportsPage setSelected={setSelected} /> },
        { path: "news", element: <NewsPage setSelected={setSelected} /> },
        { path: "*", element: <PageNotFound setSelected={setSelected} /> },
        {
          path: "news/:id",
          element: <SingleNewsPage setSelected={setSelected} />,
        },
      ],
    },
  ]);

  return (
    <div>
      <GlobalStyle />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
