import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CompanyLogo } from "../../assets";
import { AnimatePresence, motion } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faBars } from "@fortawesome/free-solid-svg-icons";

const menu = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Studio",
    path: "/studio",
  },
  {
    title: "UE5 Training",
    path: "/training",
  },
  {
    title: "Esports",
    path: "/esports",
  },
  {
    title: "News",
    path: "/news",
  },
];

export function Navbar({ selected, setShowModal }) {
  const [mo, setmo] = useState({ right: "-100vh" });
  const [mo2, setmo2] = useState({ left: "-100%" });
  const location = useLocation();
  const [display, setDisplay] = useState(true);
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec2Top = document
      .getElementById("HomeSec2")
      ?.getBoundingClientRect().top;
    const TrainingSec2Top = document
      .getElementById("TrainingSec2")
      ?.getBoundingClientRect().top;
    const EsportsSec2Top = document
      .getElementById("EsportsSec2")
      ?.getBoundingClientRect().top;
    const StudioSec2Top = document
      .getElementById("StudioSec2")
      ?.getBoundingClientRect().top;
    if (
      location.pathname.substring(0, 6) === "/news/" &&
      window.pageYOffset > 70
    ) {
      setDisplay(false);
    } else if (
      location.pathname.substring(0, 5) === "/news" &&
      window.pageYOffset > 70
    ) {
      setDisplay(false);
    } else if (HomeSec2Top <= windowHeight - 50) {
      setDisplay(false);
    } else if (TrainingSec2Top <= windowHeight - 50) {
      setDisplay(false);
    } else if (EsportsSec2Top <= windowHeight - 50) {
      setDisplay(false);
    } else if (StudioSec2Top <= windowHeight - 50) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div>
      <nav
        style={{ padding: 0, height: 0 }}
        className="navbar navbar-expand-lg position-sticky navbar-dark p-nav"
      >
        <div style={mo} className="mobile">
          <div className="mobileContainer">
            <ul className="navbar-nav">
              <li>
                <button
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="var(--text-1-500)"
                    className="bi bi-x tras"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </li>
              <li className="nav-item active">
                <Link
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  to={{ pathname: "/" }}
                  className="nav-link"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  to={{ pathname: "/studio" }}
                  className="nav-link"
                >
                  Studio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  to={{ pathname: "/training" }}
                  className="nav-link"
                >
                  UE5 Training
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  to={{ pathname: "/esports" }}
                  className="nav-link"
                >
                  Esports
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={() => {
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                  to={{ pathname: "/news" }}
                  className="nav-link"
                >
                  News
                </Link>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() => {
                    setShowModal(true);
                    setmo({ right: "-100vh" });
                    setmo2({ left: "-100%" });
                  }}
                >
                  Contact us
                </div>
              </li>
            </ul>
          </div>
          <button
            style={mo2}
            onClick={() => {
              setmo({ right: "-100vh" });
              setmo2({ left: "-100%" });
            }}
            className="darkclose"
          />
        </div>
        <div style={{ position: "absolute" }} className="navbar-brand">
          <img className="navLogo" src={CompanyLogo} alt="Barzah logo" />
        </div>
        <NavLinkWrapper>
          <AnimatePresence>
            {display &&
              menu.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ y: -100 }}
                  animate={{ y: 0 }}
                  transition={{ delay: index * 0.25 }}
                  exit={{ y: -100 }}
                  className="nav-item"
                  style={{}}
                >
                  <CustomLink
                    selected={selected}
                    style={
                      selected === index + 1
                        ? { pointerEvents: "none", cursor: "default" }
                        : {}
                    }
                    index={index + 1}
                    to={{ pathname: item.path }}
                    className="nav-link"
                  >
                    {item.title}
                  </CustomLink>
                </motion.div>
              ))}
          </AnimatePresence>
          <AnimatePresence>
            {display && (
              <motion.div
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.3 }}
                exit={{ y: -100 }}
                style={{
                  position: "fixed",
                  right: "70px",
                }}
              >
                <CustomContact
                  onClick={() => setShowModal(true)}
                  className="nav-link"
                >
                  Contact us
                </CustomContact>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!display && (
              <UpArrowDiv
                initial={{ y: -200 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.3 }}
                exit={{ y: -200 }}
              >
                <UpArrowWrapper onClick={() => scroll.scrollToTop()}>
                  <FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>
                </UpArrowWrapper>
              </UpArrowDiv>
            )}
          </AnimatePresence>
        </NavLinkWrapper>
        <CustomButton
          className="navbar-toggler"
          onClick={() => {
            setmo2({ display: "flex" });
            setmo({ right: "0px" });
          }}
        >
          <CustomFontAwesome icon={faBars} />
        </CustomButton>
      </nav>
    </div>
  );
}

const CustomFontAwesome = styled(FontAwesomeIcon)`
  color: var(--text-2-500);
`;

const CustomButton = styled.button`
  position: fixed;
  right: 20px;
  height: 50px;
  z-index: -1;
  width: 50px;
  border-radius: 10px;
  background-color: var(--text-1-500);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  top: 20px;
`;

const UpArrowDiv = styled(motion.div)`
  position: fixed;
  right: 70px;
`;

const UpArrowWrapper = styled.div`
  background: var(--text-1-500);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50px;
`;

const NavLinkWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 98px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const CustomContact = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  color: var(--text-1-500);
  font-size: 20px;
  &:active {
    color: var(--text-1-500);
  }
  &:focus {
    color: var(--text-1-500);
  }
  &:hover {
    color: var(--text-1-500);
  }
  &:hover::after {
    width: 40px;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -6px;
    transform: translate(-50%, 0%);
    height: 1px;
    width: 0px;
    border-bottom: 1px solid var(--text-1-500);
    margin: 0 auto;
    padding: 4px 0px;
    transition: all 0.72s cubic-bezier(0.52, 0.01, 0.16, 1);
  }
`;

const CustomLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ index }) => (index !== 1 ? { marginLeft: "30px" } : {})}
  text-decoration: none;
  align-items: center;
  color: var(--text-1-500);
  font-size: 20px;
  &:active {
    color: var(--text-1-500);
  }
  &:focus {
    color: var(--text-1-500);
  }
  &:hover {
    color: var(--text-1-500);
  }
  &:hover::after {
    width: 40px;
  }
  &::after {
    ${({ index, selected }) =>
      index === selected
        ? {
            display: "block",
            clear: "both",
            content: "''",
            position: "absolute",
            left: "50%",
            bottom: "-6px",
            transform: "translate(-50%, 0%)",
            height: "1px",
            width: "40px",
            borderBottom: "1px solid var(--text-1-500)",
            margin: "0 auto",
            padding: "4px 0px",
          }
        : {
            display: "block",
            clear: "both",
            content: "''",
            position: "absolute",
            left: "50%",
            bottom: "-6px",
            transform: "translate(-50%, 0%)",
            height: "1px",
            width: "0px",
            borderBottom: "1px solid var(--text-1-500)",
            margin: "0 auto",
            padding: "4px 0px",
            transition: "all 0.72s cubic-bezier(0.52, 0.01, 0.16, 1)",
          }}
  }
`;
