import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GrayLines from "./grayLines";
import PlayerLines from "./playerLines";

const PlayerCard = ({
  img,
  color,
  gradient,
  index,
  players,
  setIndex,
  setPlayers,
  setShowModal,
}) => {
  const [display, setDisplay] = useState(false);
  const handleResize = () => {
    if (window.innerWidth > 767) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <PlayerCardCont
      onMouseEnter={() => setDisplay(true)}
      onMouseLeave={() => {
        if (window.innerWidth > 767) {
          setDisplay(false);
        }
      }}
      onClick={() => {
        setShowModal(true);
        setIndex(index);
        setPlayers(players);
      }}
    >
      <PlayerWrapper color={color}>
        <PlayerImg src={img} />
        <CardImg src={players[index].logo} />
        <InfoWrapper color={color}>
          <NickName>{players[index].nickname}</NickName>
          <InfoText>{players[index].name}</InfoText>
          <InfoText>{players[index].age}</InfoText>
          <InfoText>{players[index].country}</InfoText>
        </InfoWrapper>
        <ImageGradient gradient={gradient} />
        <LineDiv>
          <PlayerLines
            display={display}
            leftLine={false}
            left={200}
            top={210}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={false}
            left={200}
            top={210}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={false}
            left={200}
            top={150}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={false}
            left={200}
            top={150}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={false}
            left={200}
            top={100}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={false}
            left={200}
            top={100}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={false}
            left={120}
            top={120}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={false}
            left={120}
            top={120}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={true}
            left={120}
            top={230}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={true}
            left={120}
            top={230}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={true}
            left={120}
            top={170}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={true}
            left={120}
            top={170}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={true}
            left={120}
            top={120}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={true}
            left={120}
            top={120}
            color="rgba(70, 71, 71, 0.5)"
          />
          <PlayerLines
            display={display}
            leftLine={true}
            left={160}
            top={100}
            color={color}
          />
          <GrayLines
            display={display}
            leftLine={true}
            left={160}
            top={100}
            color="rgba(70, 71, 71, 0.5)"
          />
        </LineDiv>
      </PlayerWrapper>
    </PlayerCardCont>
  );
};

const PlayerCardCont = styled.div`
  cursor: pointer;
  margin: 50px 0px;
  width: 300px;
  height: 300px;
`;

const CardImg = styled.img`
  position: absolute;
  left: 20px;
  bottom: 25px;
  width: 100%;
  max-width: 110px;
  z-index: 11;
`;

const ImageGradient = styled.div`
  width: 100%;
  height: 70%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  background-image: ${(props) =>
    `linear-gradient(rgba(${props.gradient}, 0), rgba(${props.gradient}, 1))`};
`;

const LineDiv = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
`;

const InfoWrapper = styled.div`
  color: ${(props) =>
    props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
      ? "var(--text-2-500)"
      : "var(--text-1-500)"};
  position: absolute;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  right: 20px;
  width: 120px;
  z-index: 11;
`;

const NickName = styled.h1`
  font-family: "Rubik";
  font-weight: 700;
  font-size: 22px;
`;

const InfoText = styled.p`
  font-size: 15px;
  font-family: "Space Mono";
`;

const PlayerWrapper = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: ${(props) =>
    props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
      ? "1px solid var(--text-2-500)"
      : "1px solid var(--text-1-500)"};
  transition: border 0.7s;
  &:hover {
    border: ${(props) => `1px solid ${props.color}`};
  }
  @media (max-width: 767px) {
    border: ${(props) => `1px solid ${props.color}`};
  }
`;

const PlayerImg = styled.img`
  height: 340px;
  bottom: 0px;
  z-index: 9;
  position: absolute;
`;

export default PlayerCard;
