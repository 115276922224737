import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { HomeCorousel1} from "../../assets";
import { newsCard } from "../../components/newsCard";
import { motion } from "framer-motion";

export const singleNewsCard = [
  {
    id: 1,
    title: "BARZAH STUDIO BEGINS DEVELOPMENT OF an immersive thrilling game, CODENAMED: Project J",
    date: "27 Feb 2023",
    description: "Project J will showcase exceptional graphics, engaging gameplay and innovative storyline.",
    news:
      "<p>Barzah Studio, a newly established organization, is proud to announce the development for an immersive thriller game, code-named: Project J.</p> <p>Project J has sparked significant interest and enthusiasm among the community.</p><p>The dedicated team behind Project J is committed to craft a game that upholds high standards of the industry, ensuring every aspect of the game exemplifies top-notch quality.</p><p>Barzah Studio will soon disclose further details regarding the game's captivating features and mechanics, as well as the anticipated release date, for the passionate gaming community. Stay tuned for updates.</p>",
    img: HomeCorousel1,
  },
  ...newsCard,
];

const SingleNewsPage = ({ setSelected }) => {
  const { id } = useParams();
  useEffect(() => {
    setSelected(0);
  });
  return (
    <SingleNewsContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 0.7}}>
      <SingleNewsWrapper>
        <NewsTitle>{singleNewsCard[id - 1].title}</NewsTitle>
        <CustomImage src={singleNewsCard[id - 1].img} />
        <NewsDate>{singleNewsCard[id - 1].date}</NewsDate>
        <DescriptionText
          dangerouslySetInnerHTML={{ __html: singleNewsCard[id - 1].news }}
        />
      </SingleNewsWrapper>
    </SingleNewsContainer>
  );
};

const NewsDate = styled.p`
  font-size: 20px;
  font-family: "Space Mono";
`;

const NewsTitle = styled.h1`
  font-size: 40px;
  text-align: center;
  font-family: "Rubik";
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  max-width: 800px;
`;

const DescriptionText = styled.div`
  line-height: 1.5;
  width: 100%;
  padding: 0px 50px;
  padding-bottom: 50px;
  max-width: 1100px;
  font-size: 20px;
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const SingleNewsContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
`;

const SingleNewsWrapper = styled.div`
  max-width: 1500px;
  width: 100%;
  color: var(--text-1-500);
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CustomImage = styled.img`
  width: 100%;
`;

export default SingleNewsPage;
