import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const svgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
  exit: { opacity: 0 },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    pathLength: 0,
  },
};

const PlayerLines = ({ color, leftLine, left, top, display }) => {
  return (
    <>
      <AnimatePresence>
        {display && (
          <CustomSvg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            variants={svgVariants}
            initial="hidden"
            color={color}
            left={left}
            top={top}
            animate="visible"
            exit="exit"
          >
            <motion.path
              variants={pathVariants}
			  d={leftLine ? 'M0 0 L-200 -200' : 'M0 0 L200 -200'}
              fill="none"
            />
          </CustomSvg>
        )}
      </AnimatePresence>
    </>
  );
};

const CustomSvg = styled(motion.svg)`
  width: 80px;
  z-index: 7;
  position: absolute;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  overflow: visible;
  stroke: ${(props) => props.color};
  stroke-width: 25;
`;

export default PlayerLines;
