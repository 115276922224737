import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  StudioCorousel1,
  StudioCorousel2,
  StudioCorousel3,
  StudioCorousel4,
  StudioCorousel5,
} from "../../assets";

const imgSlide = [
  {
    name: "Enemy1",
    img: StudioCorousel5,
  },
  {
    name: "Enemy2",
    img: StudioCorousel1,
  },
  {
    name: "Enemy3",
    img: StudioCorousel4,
  },
  {
    name: "Enemy4",
    img: StudioCorousel3,
  },
  {
    name: "Enemy5",
    img: StudioCorousel2,
  },
];

const StudioLargeCarousel = () => {
  return (
    <CarouselDiv id="StudioSec4">
      <SlideContainer>
        <Swiper
          style={{ height: "auto" }}
          allowTouchMove={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={1500}
          navigation={true}
          centeredSlides={true}
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            900: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            540: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
            },
          }}
        >
          {imgSlide.map((slide) => (
            <CustomSwiperSlidePic key={slide.title}>
              <TextWrapper>
                <ImgTitle>{slide.title}</ImgTitle>
              </TextWrapper>
              <ImgWrapper>
                <img src={slide.img} alt="slider images" />
              </ImgWrapper>
            </CustomSwiperSlidePic>
          ))}
        </Swiper>
      </SlideContainer>
    </CarouselDiv>
  );
};

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(27, 28, 28, 0) 40%, rgba(27, 28, 28, 1) 100%);
`;

const TextWrapper = styled.div`
  position: absolute;
  bottom: -50px;
  z-index: 99;
  padding: 100px;
  @media (max-width: 1330px) {
    bottom: -80px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
  @media (max-width: 380px) {
    padding: 50px;
    bottom: -35px;
  }
`;

const ImgTitle = styled.h1`
  z-index: 99;
  font-size: 15px;
  padding: 10px;
  @media (max-width: 1330px) {
    font-size: 10px;
  }
  @media (max-width: 500px) {
    font-size: 8px;
    padding: 5px;
  }
`;

const SlideContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const CustomSwiperSlidePic = styled(SwiperSlide)`
  position: relative;
  color: var(--text-1-500);
  & img {
	position: relative;
	z-index: -1;
  }
  & img,
  & p,
  & h1 {
    transition: all 1.5s;
    opacity: 0.5;
  }

  background: var(--main-600);
  &.swiper-slide-prev img,
  &.swiper-slide-prev p,
  &.swiper-slide-prev h1 {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-active img,
  &.swiper-slide-active p,
  &.swiper-slide-active h1 {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-next img,
  &.swiper-slide-next p,
  &.swiper-slide-next h1 {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-duplicate-prev img,
  &.swiper-slide-duplicate-prev p,
  &.swiper-slide-duplicate-prev h1 {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-duplicate-active img,
  &.swiper-slide-duplicate-active p,
  &.swiper-slide-duplicate-active h1 {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-duplicate-next img,
  &.swiper-slide-duplicate-next p,
  &.swiper-slide-duplicate-next h1 {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
`;

const CarouselDiv = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--text-4-500);
  @media (max-width: 500px) {
    background: none;
  }
  margin-top: -1px;
  font-family: "Space Mono";
`;

export default StudioLargeCarousel;
