import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  CompanyADG,
  CompanyTitan,
  CompanyWings,
} from "../../assets";

const CompaniesTrust = () => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec7Top = document
      .getElementById("HomeSec7")
      .getBoundingClientRect().top;
    if (HomeSec7Top <= windowHeight - 200) {
      setLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <TrustContainer id="HomeSec7">
      <GameDev>
        <AnimWrapper>
          <DoubleText
            style={{
              WebkitTextStroke: "1px rgb(255, 255, 255)",
              opacity: logo ? 1 : 0,
              left: logo ? "0px" : "-20%",
            }}
          >
            Our
            <span style={{ color: "#fff" }}> Partners </span>
          </DoubleText>
        </AnimWrapper>
      </GameDev>
      <ImgsWrapper xs={1} sm={2} md={2} lg={5} xl={5} xxl={5}>
        <CustomCol>
          <CustomImg src={CompanyTitan} />
        </CustomCol>
        <CustomCol>
          <CustomImg src={CompanyADG} />
        </CustomCol>
        <CustomCol>
          <CustomImg src={CompanyWings} />
        </CustomCol>
        {/* <CustomCol>
          <CustomImg src={CompanyRealityForge} />
        </CustomCol> */}
      </ImgsWrapper>
    </TrustContainer>
  );
};

const AnimWrapper = styled.div`
  position: relative;
  width: 420px;
  height: 60px;
  @media (max-width: 1500px) {
    width: 340px;
    height: 60px;
  }
  @media (max-width: 1024px) {
    width: 270px;
    height: 60px;
  }
  @media (max-width: 768px) {
    width: 220px;
    height: 40px;
  }
`;

const CustomCol = styled(Col)`
  width: 250px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const GameDev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 200px;
  @media (max-width: 1500px) {
    height: 150px;
  }
  @media (max-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 767px) {
    height: 120px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`;

const DoubleText = styled.h2`
  transition: all 0.7s;
  font-size: 50px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  letter-spacing: 3px;
  font-weight: 700;
  position: absolute;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 40px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
  }
`;

const CustomImg = styled.img`
  width: 250px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const ImgsWrapper = styled(Row)`
  margin: auto;
  padding: 20px 20px 50px 20px;
  width: 100%;
  gap: 100px;
  /* max-width: 860px; */
  max-width: 1000px;
  align-items: center;
  @media (max-width: 1199px) {
    max-width: 330px;
  }
  @media (max-width: 767px) {
    max-width: 265px;
  }
`;

const TrustContainer = styled.div`
  height: 100%;
  padding: 50px 0px 50px 0px;
  position: relative;
  @media (max-width: 600px) {
    padding: 20px 0px 20px 0px;
  }
`;

export default CompaniesTrust;
