import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ArrowsBlack, ArrowsWhite, EsportsVideo, LinesBlack, LinesWhite } from "../../assets";
import PlayerCard from "../playerCard";
import { AnimatePresence, motion } from "framer-motion";
import ReactPlayer from "react-player";

const svgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
  exit: { opacity: 0 },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    pathLength: 0,
  },
};

const EsportsPlayers = ({
  color,
  left,
  right,
  gradient,
  display,
  players,
  setPlayers,
  setIndex,
  setShowModal,
}) => {
  const [px, setPx] = useState("-130px");
  const [widt, setWidt] = useState(0);
  const [heit, setHeit] = useState(0);
  const [videoShow, setVideoShow] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const EsportsSec2Top = document
      .getElementById("EsportsVideo")
      .getBoundingClientRect().top;
    if (EsportsSec2Top <= windowHeight - 150) {
      setVideoShow(true);
    }
  };

  const handleResize = () => {
    setWidt(document.getElementById("picture").offsetWidth * 1);
    setHeit(document.getElementById("picture").offsetHeight * 1);
    if (window.innerWidth <= 954) {
      setPx("-80px");
    } else {
      setPx("-130px");
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 954) {
      setPx("-80px");
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <MainContainer
      color={color}
      style={{
        opacity: display ? 1 : 0,
      }}
    >
      <MainImgWrapper>
        <MainLeftImg
          style={{ left: display ? px : "-900px" }}
          src={left}
          color={color}
        ></MainLeftImg>
        <MainImg
          style={{
            bottom: display || color === "rgb(242,205,99)" ? "0px" : "500px",
          }}
          src={players[0].logo}
          color={color}
        ></MainImg>
        <MainRightImg
          style={{ right: display ? px : "-900px" }}
          src={right}
          color={color}
        ></MainRightImg>
      </MainImgWrapper>
      <ImageWrapper id="EsportsVideo">
        <AnimatePresence>
          {videoShow && (
            <CustomSvg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              variants={svgVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <motion.path
                variants={pathVariants}
                d={`M0 0 L${widt} 0 L${widt} ${heit} L0 ${heit} L0 -1Z`}
                fill="none"
              />
            </CustomSvg>
          )}
        </AnimatePresence>
        <CustomReactPlayer
          onReady={handleResize}
          id="picture"
          url={EsportsVideo}
          controls={true}
          muted={true}
        />
        {/* <CustomImg onLoad={handleResize} id="picture" src={EsportDescImg} /> */}
      </ImageWrapper>
      <CustomRow
        style={{
          paddingBottom:
            color === "rgb(254,70,85)" || color === "rgb(255,58,57)"
              ? "100px"
              : 0,
        }}
        sm={1}
        md={2}
        xs={1}
        lg={2}
        xl={3}
        xxl={4}
      >
        {color === "rgb(254,70,85)" || color === "rgb(255,58,57)" ? (
          <IconsWrapper>
            <LinesImg
              style={{ width: "20px", top: 0, right: "20px" }}
              src={LinesBlack}
            />
            <ArrowsImg
              style={{ width: "12px", bottom: "70px", right: "40px" }}
              src={ArrowsBlack}
            />
            <ArrowsImg
              style={{ width: "12px", top: "30px", rotate: "180deg" }}
              src={ArrowsBlack}
            />
            <LinesImg
              style={{
                width: "20px",
                bottom: "50px",
                left: "50px",
                rotate: "-90deg",
              }}
              src={LinesBlack}
            />
          </IconsWrapper>
        ) : (
          <IconsWrapper>
            <LinesImg style={{ left: 0, bottom: -100 }} src={LinesWhite} />
            <ArrowsImg style={{ right: 0, bottom: 0 }} src={ArrowsWhite} />
          </IconsWrapper>
        )}
        {players.map((item, idx) => (
          <Col key={idx}>
            <PlayerCard
              setShowModal={setShowModal}
              players={players}
              setPlayers={setPlayers}
              setIndex={setIndex}
              index={idx}
              gradient={gradient}
              img={item.img}
              color={color}
            />
          </Col>
        ))}
      </CustomRow>
    </MainContainer>
  );
};


const CustomReactPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
  & video {
    width: 900px !important;
    height: auto !important;
    @media (max-width: 1780px) {
      width: 600px !important;
    }
    @media (max-width: 1070px) {
      width: 400px !important;
    }
    @media (max-width: 600px) {
      width: 350px !important;
    }
    @media (max-width: 400px) {
      width: 280px !important;
    }
    @media (max-width: 300px) {
      width: 250px !important;
    }
  }
`;

const CustomSvg = styled(motion.svg)`
  width: 100px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
  stroke: var(--text-1-500);
  stroke-width: 2;
`;

const ImageWrapper = styled.div`
  position: relative;
`;


const IconsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ArrowsImg = styled.img`
  width: 40px;
  position: absolute;
`;

const LinesImg = styled.img`
  width: 20px;
  position: absolute;
`;

const CustomRow = styled(Row)`
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 1360px;
  @media (max-width: 1399px) {
    max-width: 1100px;
  }
  @media (max-width: 1199px) {
    max-width: 700px;
  }
  @media (max-width: 991px) {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    max-width: 330px;
  }
`;

const MainContainer = styled.div`
  transition: opacity 0.7s;
  transform: translateZ(0);
  padding: ${(props) =>
    props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
      ? "450px 20px 0px 20px"
      : "0px 20px 0px 20px"};
  @media (max-width: 1500px) {
    padding: ${(props) =>
      props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
        ? "340px 20px 0px 20px"
        : "0px 20px 0px 20px"};
  }
  @media (max-width: 1024px) {
    padding: ${(props) =>
      props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
        ? "250px 20px 0px 20px"
        : "0px 20px 0px 20px"};
  }
  @media (max-width: 767px) {
    padding: ${(props) =>
      props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
        ? "150px 20px 0px 20px"
        : "0px 20px 0px 20px"};
  }
  @media (max-width: 500px) {
    padding: ${(props) =>
      props.color === "rgb(254,70,85)" || props.color === "rgb(255,58,57)"
        ? "100px 20px 0px 20px"
        : "0px 20px 0px 20px"};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex-direction: column;
`;

const MainImgWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 180px;
  @media (max-width: 1584px) {
    height: 120px;
  }
  @media (max-width: 954px) {
    height: 180px;
  }
  justify-content: center;
`;

const MainLeftImg = styled.img`
  position: absolute;
  transition: all 0.7s;
  width: 600px;
  left: -220px;
  @media (max-width: 1584px) {
    width: 400px;
  }
  @media (max-width: 767px) {
    visibility: hidden;
    transition: none;
    transition-delay: none;
  }
`;

const MainRightImg = styled.img`
  position: absolute;
  transition: all 0.7s;
  width: 600px;
  transform: scale(-1, 1);
  @media (max-width: 1584px) {
    width: 400px;
  }
  @media (max-width: 767px) {
    visibility: hidden;
    transition: none;
    transition-delay: none;
  }
`;

const MainImg = styled.img`
  position: absolute;
  transition: bottom 0.7s;
  width: 500px;
  @media (max-width: 1584px) {
    width: 300px;
  }
`;

export default EsportsPlayers;
