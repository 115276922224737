import styled from "styled-components";
import { BarzahSolidWhite } from "../../assets";
// import { useEffect, useState } from "react";
import {
  // faDiscord,
  faInstagram,
  // faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Footer() {
  //   const [display, setDisplay] = useState(false);
  //   const handleScroll = () => {
  //     const windowHeight = window.innerHeight;
  //     const footerTop = document
  //       .getElementById("Footer")
  //       .getBoundingClientRect().top;
  //     if (footerTop > windowHeight - 300) {
  //       setDisplay(false);
  //     } else {
  //       setDisplay(true);
  //     }
  //   };
  //   useEffect(() => {
  //     window.addEventListener("scroll", handleScroll, { passive: true });
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);
  return (
    <FooterContainer id="Footer">
      <SocialContainer>
        <FooterCopyRights>
          © {(new Date().getFullYear())} Barzah Studio, Inc. All Rights Reserved ---- UAE, Abu Dhabi,
          Yas Creative Hub
        </FooterCopyRights>
        <BarzahSolid src={BarzahSolidWhite} />
        <SocialContainerIcons>
          <a
            href="https://twitter.com/Barzahstudio"
            target="_blank"
            rel="noreferrer"
          >
            <SocialImg icon={faTwitter} color="white" />
          </a>
          <a
            href="https://www.instagram.com/barzahstudio/"
            target="_blank"
            rel="noreferrer"
          >
            <SocialImg icon={faInstagram} color="white" />
          </a>
          {/* <SocialImg icon={faLinkedin} />
          <SocialImg icon={faDiscord} /> */}
        </SocialContainerIcons>
      </SocialContainer>
    </FooterContainer>
    // <InfoContainer>

    // </InfoContainer>
  );
}

const SocialImg = styled(FontAwesomeIcon)`
  font-size: 40px;
  width: 60px;
  @media (max-width: 1300px) {
    font-size: 30px;
    width: 50px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    width: 32px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--text-4-500);
  padding: 40px 10px;
  @media (max-width: 1300px) {
    flex-direction: column-reverse;
    gap: 20px;
    padding: 15px 10px;
  }
  @media (max-width: 480px) {
    gap: 15px;
  }
`;
const SocialContainerIcons = styled.div`
  display: flex;
  color: var(--text-1-500);
  padding: 0px 30px;
  width: 540px;
  justify-content: right;
  @media (max-width: 1500px) {
    width: 400px;
  }
  @media (max-width: 1300px) {
    width: auto;
  }
`;

const FooterCopyRights = styled.h1`
  display: flex;
  justify-content: left;
  color: var(--text-1-500);
  font-size: 13px;
  padding: 0px 30px;
  max-width: 540px;
  @media (max-width: 1500px) {
    max-width: 400px;
  }
  @media (max-width: 1300px) {
    padding: 0;
    max-width: auto;
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 9px;
  }
`;

const FooterContainer = styled.footer`
  height: 100%;
  border-top: 1px solid var(--text-1-500);
  @media (max-width: 800px) {
    height: 100%;
    background: none;
    padding-top: 10px;
  }
  background-color: var(--text-4-500);
`;

const BarzahSolid = styled.img`
  z-index: 42;
  width: 300px;
  @media (max-width: 1300px) {
    width: 180px;
  }
  @media (max-width: 480px) {
    width: 120px;
  }
`;

// const BarzahLogo = styled.img`
//   position: absolute;
//   z-index: 50;
//   width: 100%;
//   max-width: 170px;
//   transition: all 0.7s;
//   margin-bottom: 7vh;
//   @media (max-width: 1500px) {
//     max-width: 150px;
//   }
//   @media (max-width: 1024px) {
//     max-width: 130px;
//     margin-bottom: 8vh;
//   }
//   @media (max-width: 767px) {
//     max-width: 100px;
//     margin-bottom: 9vh;
//   }
// `;
