import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

export const GlobalStyle = createGlobalStyle`
	${reset}

	:root {
		--main-500: #2b2e2e;
		--main-600: #1b1c1c;
		--main-2-500: #eff4f3;
		--text-1-500: #fff;
		--text-2-500: #000;
		--text-3-500: #5f5f5f
	}
    
	*{
		margin: 0;
		padding: 0;
	};
	body{
		background-color: var(--main-600);
		font-family: 'Jost';
		overflow-x: hidden;
		height: 100vh;
	}
`;
