import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ArrowsBlack,
  ArrowsWhite,
  EsportText,
  LinesBlack,
  LinesWhite,
} from "../../assets";

const HomeEsport = ({ title, description, img, color, section }) => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec5Top = document
      .getElementById(color ? "HomeSec4" : "HomeSec5")
      .getBoundingClientRect().top;
    if (HomeSec5Top <= windowHeight - 320) {
      setLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <EsportHomeCont color={color} id={color ? "HomeSec4" : "HomeSec5"}>
      <EsportLogo>
        <AnimWrapper>
          {color ? (
            <DoubleText
              style={{
                zIndex: 9,
                WebkitTextStroke: "1px rgb(0, 0, 0)",
              }}
            >
              Studio
            </DoubleText>
          ) : (
            <AnimImg
              src={EsportText}
              style={{
                opacity: logo ? 1 : 0,
                left: logo ? "0px" : "20%",
              }}
            />
          )}
        </AnimWrapper>
      </EsportLogo>
      <InfoContainer>
        <ArrowWrapper>
          <ArrowImg color={color} src={color ? ArrowsBlack : ArrowsWhite} />
          <LinesImg src={color ? LinesBlack : LinesWhite} />
        </ArrowWrapper>
        <InfoWrapper color={color}>
          <TitleText>{title}</TitleText>
          <DescText>{description}</DescText>
          <Link style={{ textDecoration: "none" }} to="/esports">
            <CustomButton>{section}</CustomButton>
          </Link>
        </InfoWrapper>
        <CustomImg src={img} />
      </InfoContainer>
    </EsportHomeCont>
  );
};

const CustomButton = styled.button`
  color: var(--text-1-500);
  background-color: transparent;
  border: 1px solid var(--text-1-500);
  width: 170px;
  padding: 10px 20px 12px calc(20px - 0.02em);
  justify-content: center;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.02em;
  font-family: Rubik, sans-serif;
  font-weight: 700;
  line-height: calc(30 / 17);
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  isolation: isolate;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  &:hover::before {
    clip-path: inset(0 0 0 0);
  }
  &::after {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: 5px;
    background-color: currentColor;
    position: absolute;
    bottom: 3px;
    left: 3px;
    pointer-events: none;
  }
  &::before {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px - 4px);
    background-color: currentColor;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96),
      -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    pointer-events: none;
    mix-blend-mode: difference;
    -webkit-clip-path: inset(100% 0 0 0);
    clip-path: inset(100% 0 0 0);
  }
`;

const DoubleText = styled.h2`
  font-size: 250px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  letter-spacing: 3px;
  font-weight: 700;
  white-space: nowrap;
  color: var(--main-600);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 180px;
  }
  @media (max-width: 1024px) {
    font-size: 130px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
  }
  @media (max-width: 500px) {
    font-size: 60px;
  }
`;

const AnimWrapper = styled.div`
  position: relative;
  width: 1000px;
  height: 350px;
  @media (max-width: 1500px) {
    width: 800px;
    height: 250px;
  }
  @media (max-width: 1024px) {
    width: 600px;
    height: 200px;
  }
  @media (max-width: 767px) {
    width: 400px;
    height: 120px;
  }
  @media (max-width: 500px) {
    width: 300px;
    height: 100px;
  }
`;

const LinesImg = styled.img`
  width: 30px;
  rotate: -90deg;
  position: absolute;
  right: 100px;
  bottom: -50px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 300px;
  top: 0;
  width: 1300px;
  height: 500px;
  @media (max-width: 1300px) {
    display: none;
  }
  @media (max-width: 1500px) {
    margin-top: 200px;
  }
`;

const ArrowImg = styled.img`
  position: absolute;
  left: 0;
  rotate: ${(props) => (props.color ? "180deg" : "-90deg")};
  width: ${(props) => (props.color ? "20px" : "55px")};
  top: 80px;
`;

const AnimImg = styled.img`
  transition: all 0.7s;
  position: absolute;
  width: 1000px;
  @media (max-width: 1500px) {
    width: 800px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 400px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const CustomImg = styled.img`
  width: 600px;
  @media (max-width: 1100px) {
    width: 300px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 50px;
    text-align: center;
  }
`;

const InfoWrapper = styled.div`
  color: ${(props) =>
    props.color ? "var(--text-2-500)" : "var(--text-1-500)"};
  width: 100%;
  padding: 20px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  font-family: "Rubik";
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 1100px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DescText = styled.p`
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 1100px) {
    font-size: 15px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const EsportLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 350px;
  @media (max-width: 1500px) {
    height: 250px;
  }
  @media (max-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 767px) {
    height: 120px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`;

const EsportHomeCont = styled.div`
  background: ${(props) =>
    props.color ? "var(--main-2-500)" : "var(--main-600)"};
  color: var(--text-1-500);
  z-index: 10;
  height: 100%;
  padding: ${(props) =>
    props.color ? "50px 0px 150px 0px" : "50px 0px 250px 0px"};
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
`;

export default HomeEsport;
