import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { ApexLogo, TekkenLogo, ValLogo } from "../../assets";

const ComingSoonEsports = () => {
  return (
    <ComingSoonContainer id="EsportsSec4">
      <GameDev>
        <AnimWrapper>
          <DoubleText>
          ON THE HORIZON
          </DoubleText>
        </AnimWrapper>
      </GameDev>
      <ImgsWrapper xs={1} sm={2} md={2} lg={5} xl={5} xxl={5}>
        <CustomCol>
          <CustomImg src={ApexLogo} />
        </CustomCol>
        <CustomCol>
          <CustomImg src={TekkenLogo} />
        </CustomCol>
        <CustomCol>
          <CustomImg src={ValLogo} />
        </CustomCol>
        {/* <CustomCol>
          <CustomImg src={CompanyRealityForge} />
        </CustomCol> */}
      </ImgsWrapper>
    </ComingSoonContainer>
  );
};


const AnimWrapper = styled.div`
  position: relative;
  width: 450px;
  height: 60px;
  @media (max-width: 1500px) {
    width: 365px;
    height: 60px;
  }
  @media (max-width: 1024px) {
    width: 285px;
    height: 60px;
  }
  @media (max-width: 768px) {
    width: 245px;
    height: 40px;
  }
  @media (max-width: 500px) {
    height: 20px;
  }
`;

const CustomCol = styled(Col)`
  width: 250px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const GameDev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 200px;
  @media (max-width: 1500px) {
    height: 150px;
  }
  @media (max-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 767px) {
    height: 120px;
  }
  @media (max-width: 500px) {
    height: 100px;
  }
`;

const DoubleText = styled.h2`
  transition: all 0.7s;
  font-size: 50px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  letter-spacing: 3px;
  font-weight: 700;
  position: absolute;
  white-space: nowrap;
  color: rgb(28,28,28);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 40px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
  }
`;

const CustomImg = styled.img`
  width: 250px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const ImgsWrapper = styled(Row)`
  margin: auto;
  padding: 50px 20px 20px 20px;
  width: 100%;
  gap: 100px;
  /* max-width: 860px; */
  max-width: 1000px;
  align-items: center;
  @media (max-width: 1199px) {
    max-width: 330px;
  }
  @media (max-width: 767px) {
    max-width: 240px;
  }
`;

const ComingSoonContainer = styled.div`
  height: 100%;
  padding-bottom: 50px;
  position: relative;
`;

export default ComingSoonEsports;
