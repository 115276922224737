import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowsWhite, HomeCorousel1, LinesWhite} from "../../assets";
import { Link } from "react-router-dom";
import NewsClipArt from "../../components/newsClipArt";
import { motion } from "framer-motion";
// import { singleNewsCard } from "../singleNewsPage";

const NewsPage = ({ setSelected }) => {
  const [widt, setWidt] = useState(0);
  const [heit, setHeit] = useState(0);

  const handleResize = () => {
    setWidt(document.getElementById("img-width").offsetWidth * 0.96);
    setHeit(document.getElementById("img-width").offsetHeight * 1.06);
  };
  useEffect(() => {
    setSelected(5);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleLoad = () => {
    setWidt(document.getElementById("img-width").offsetWidth * 0.96);
    setHeit(document.getElementById("img-width").offsetHeight * 1.06);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
      style={{ padding: "150px 0px 0px 0px" }}
    >
      <NewsPicCont>
        <ImgNewsCont>
          <ImgsWrapper>
            <LinesImg style={{ left: "-70px", top: "10vw" }} src={LinesWhite} />
            <ArrowsImg
              style={{ bottom: "10vw", right: "-80px", rotate: "-90deg" }}
              src={ArrowsWhite}
            />
          </ImgsWrapper>
          <CustomSvg>
            <CustomPath
              d={`M0 0 L${widt} 0 L${widt} ${heit} L0 ${heit} L0 0Z`}
            ></CustomPath>
          </CustomSvg>
          <CustomLink to="/news/1">
            <ImageWrapper>
              <CustomImage
                onLoad={handleLoad}
                id="img-width"
                src={HomeCorousel1}
              />
              <ImageDark />
              <TextWrapper>
                <NewsTitle>BARZAH STUDIO BEGINS DEVELOPMENT OF an immersive thrilling game, 
CODENAMED: Project J</NewsTitle>
                {/* <NewsDesc>
                {singleNewsCard[0].description}
                </NewsDesc> */}
              </TextWrapper>
            </ImageWrapper>
          </CustomLink>
        </ImgNewsCont>
      </NewsPicCont>
      <NewsClipArt />
    </motion.div>
  );
};

const ImgsWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  height: 100%;
  @media (max-width: 980px) {
    display: none;
  }
`;

const LinesImg = styled.img`
  width: 30px;
  position: absolute;
`;

const ArrowsImg = styled.img`
  width: 50px;
  position: absolute;
`;

const CustomPath = styled.path`
  fill: none;
  stroke: var(--text-1-500);
  stroke-width: 1;
`;

const CustomSvg = styled.svg`
  width: 80px;
  position: absolute;
  left: calc(2%);
  top: calc(-3%);
  z-index: 999;
  overflow: visible;
`;

const NewsPicCont = styled.div`
  width: 100%;
  padding: 0 120px;
  @media (max-width: 980px) {
    padding: 0 20px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageDark = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  width: 100%;
  padding-top: 56.25%;
`;

const CustomImage = styled.img`
  transform: scale(1);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 1;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  color: var(--text-1-500);
  position: relative;
  cursor: pointer;
  &:hover ${CustomImage} {
    transform: scale(1.2);
  }
  &:hover ${ImageDark} {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const TextWrapper = styled.div`
  width: 830px;
  z-index: 11;
  left: 50px;
  @media (max-width: 1224px) {
    width: 500px;
    bottom: 30px;
    left: 40px;
  }
  @media (max-width: 800px) {
    width: 320px;
    bottom: 10px;
    left: 30px;
  }
  @media (max-width: 520px) {
    width: 220px;
    bottom: 10px;
    left: 20px;
  }
  position: absolute;
  bottom: 50px;
`;

const ImgNewsCont = styled.div`
  border: none;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  position: relative;
  background: var(--main-600);
`;

const NewsTitle = styled.h1`
text-transform: uppercase;
  font-size: 30px;
  font-family: "Rubik";
  font-weight: 700;
  @media (max-width: 1224px) {
    font-size: 30px;
  }
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 520px) {
    font-size: 15px;
  }
  color: var(--text-1-500);
  margin-bottom: 10px;
`;

// const NewsDesc = styled.p`
//   font-size: 15px;
//   line-height: 1.5;
//   font-family: "Space Mono";
//   @media (max-width: 1224px) {
//     font-size: 15px;
//   }
//   @media (max-width: 800px) {
//     font-size: 10px;
//   }
//   @media (max-width: 520px) {
//     font-size: 8px;
//   }
//   color: #d3d3d3;
// `;

export default NewsPage;
