import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TrainingInfoPic from "../trainingInfoPic";
import DrawLine from "../drawLine";
import DrawLineRes from "../drawLine/drawLineRes";
import { Training1, Training2, Training3, Training4 } from "../../assets";

const TrainingInfo = () => {
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [display2, setDisplay2] = useState(false);
  const [display3, setDisplay3] = useState(false);
  const [responsive, setResponsive] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 1011) {
      setResponsive(true);
    } else {
      setResponsive(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const TrainingSec3Top = document
        .getElementById("TrainingSec3")
        .getBoundingClientRect().top;
      const maxLine1 = window.innerWidth < 1011 ? 350 : 350;
      const maxLine2 = window.innerWidth < 1011 ? 800 : 700;
      const maxLine3 = window.innerWidth < 1011 ? 1220 : 1070;
      const maxLine4 = window.innerWidth < 1011 ? 1650 : 1420;
      if (TrainingSec3Top > windowHeight - maxLine1) {
        setDisplay(false);
      } else if (!display) {
        setDisplay(true);
      }
      if (TrainingSec3Top > windowHeight - maxLine2) {
        setDisplay1(false);
      } else if (!display1) {
        setDisplay1(true);
      }
      if (TrainingSec3Top > windowHeight - maxLine3) {
        setDisplay2(false);
      } else if (!display2) {
        setDisplay2(true);
      }
      if (TrainingSec3Top > windowHeight - maxLine4) {
        setDisplay3(false);
      } else if (!display3) {
        setDisplay3(true);
      }
    };
    if (window.innerWidth <= 1011) {
      setResponsive(true);
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [display, display1, display2, display3]);
console.log(responsive);
  return (
    <InfoContainer>
      <InfoWrapper style={{ opacity: display ? 1 : 0 }} id="TrainingSec3">
        {responsive ? (
          <>
            <DrawLineRes
              id="animpath-res"
              pos={0}
              display={display}
              display2={display1}
              size={340}
            />
            <DrawLineRes
              id="animpath1-res"
              pos={340}
              display={display1}
              display2={display2}
              size={810}
            />
            <DrawLineRes
              id="animpath2-res"
              pos={810}
              display={display2}
              display2={display3}
              size={1360}
            />
            <DrawLineRes
              id="animpath3-res"
              pos={1360}
              display={display3}
              size={1870}
            />{" "}
          </>
        ) : (
          <>
            <DrawLine
              id="animpath"
              pos={0}
              display={display}
              display2={display1}
              size={250}
            />
            <DrawLine
              id="animpath1"
              pos={250}
              display={display1}
              display2={display2}
              size={700}
            />
            <DrawLine
              id="animpath2"
              pos={700}
              display={display2}
              display2={display3}
              size={1150}
            />
            <DrawLine
              id="animpath3"
              pos={1150}
              display={display3}
              size={1600}
            />{" "}
          </>
        )}
        <LineWithCircle>
          <Circle top={200} />
          <Circle top={560} />
          <Circle top={920} />
          <Circle top={1280} />
        </LineWithCircle>
        <TrainingInfoPic
          display={display}
          left={true}
          img={Training1}
          title="GET THE NECESSARY KNOWLEDGE"
          description="By learning Unreal Engine 5, you can gain the necessary knowledge to create stunning and engaging games that push the boundaries of what's possible in the gaming industry."
        />
        <TrainingInfoPic
          display={display1}
          left={false}
          img={Training2}
          title="EXPAND YOUR SOFTWARE ENGINEERING SKILLS"
          description="By learning Unreal Engine 5, you can expand your skillset and enhance their abilities to create engaging and immersive experiences for a wide range of industries."
        />
        <TrainingInfoPic
          display={display2}
          left={true}
          img={Training3}
          title="CONNECT WITH OTHERS IN THE GAMING INDUSTRY"
          description="By developing your own game, you will acquire invaluable experience and 
          demonstrate your abilities. Whether working independently or in collaboration with 
          a team, Unreal Engine 5 offers the ideal platform to transform your game 
          development aspirations into tangible accomplishments."
        />
        <TrainingInfoPic
          display={display3}
          left={false}
          img={Training4}
          title="Create your first game and become part of the community"
          description="By creating your own game, individuals can gain valuable experience, showcase your skills, and even attract the attention of potential employers. Whether working solo or collaborating with others, Unreal Engine 5 provides the platform to turn your game development dreams into a reality."
        />
      </InfoWrapper>
    </InfoContainer>
  );
};

const LineWithCircle = styled.div`
  width: 1px;
  height: 93%;
  background-color: var(--text-1-500);
  position: absolute;
  left: 50%;
  top: 50px;
  @media (max-width: 1011px) {
    left: 60px;
    top: 0px;
    height: 96%;
  }
`;

const Circle = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background-color: white;
  border: solid 1px black;
  border-radius: 50%;
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: -4.5px;
  @media (max-width: 1011px) {
    top: ${(props) =>
      props.top === 200
        ? "270px"
        : props.top === 560
        ? "645px"
        : props.top === 920
        ? "1085px"
        : "1490px"};
  }
`;

const InfoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  height: 100%;
  width: 100%;
  transition: opacity 0.7s;
  padding: 100px 0px;
  position: relative;
  @media (max-width: 1011px) {
    width: 600px;
  }
`;

export default TrainingInfo;
