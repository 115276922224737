import { ParallaxBanner } from "react-scroll-parallax";
import {
  BarzahSolidWhite,
  ParallaxBg,
  ParallaxDesertDest,
  ParallaxDesertDestLeft,
  StudioNomadImg,
} from "../../../assets";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { AnimatePresence, useAnimation } from "framer-motion";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import BorderAnim from "./animation_lefu9xjf.json";

export function HomeParallaxBanner() {
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showParallax, setShowParallax] = useState(true);
  const DestLeft1Anim = useAnimation();
  const BgAnim = useAnimation();
  const NomadAnim = useAnimation();
  const DestRight1Anim = useAnimation();
  const DestLeft2Anim = useAnimation();
  const DestRight2Anim = useAnimation();

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    const offsetFactor = 15;
    DestLeft1Anim.start({
      x: moveX / -offsetFactor,
      y: moveY / offsetFactor,
    });
    BgAnim.start({
      x: moveX / offsetFactor,
      y: moveY / offsetFactor,
    });
    NomadAnim.start({
      x: moveX / -offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestRight1Anim.start({
      x: moveX / offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestLeft2Anim.start({
      x: moveX / offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestRight2Anim.start({
      x: moveX / -offsetFactor,
      y: moveY / offsetFactor,
    });
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec2Top = document
      .getElementById("HomeSec2")
      .getBoundingClientRect().top;
    const HomeSec3Top = document
      .getElementById("HomeSec3")
      .getBoundingClientRect().top;
    if (HomeSec2Top <= windowHeight - 150) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
    if (HomeSec3Top <= windowHeight - 500) {
      setShowParallax(false);
    } else {
      setShowParallax(true);
    }
  };
  useEffect(() => {
    setDisplay(true);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const text2 = {
    translateY: [0, -100],
    speed: -10,
    scale: [1, 1.2],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            {/* Border */}
            <TitleWrapper>
              <CustomLottie animationData={BorderAnim} loop={false} />
            </TitleWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const left1 = {
    translateY: [0, -50],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestLeft1ImgComp
              animate={DestLeft1Anim}
              src={ParallaxDesertDestLeft}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const left2 = {
    translateY: [0, -150],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestLeft2ImgComp
              animate={DestLeft2Anim}
              src={ParallaxDesertDestLeft}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const right1 = {
    translateY: [0, -50],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestRight1ImgComp
              animate={DestRight1Anim}
              src={ParallaxDesertDest}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const right2 = {
    translateY: [0, -110],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestRight2ImgComp
              animate={DestRight2Anim}
              src={ParallaxDesertDest}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  
  const textBack = {
    translateY: [0, -100],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            {/* Solid */}
            <TitleWrapper>
              <AnimatePresence>
                {display && !loading && (
                  <BarzahSolid
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    src={BarzahSolidWhite}
                  />
                )}
              </AnimatePresence>
            </TitleWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  return (
    <ParallaxContainer
      onMouseMove={handleMouseMove}
      style={{
        opacity: display ? 1 : 0,
        transitionDelay: display ? "0.2s" : "0s",
      }}
    >
      <BackgroundGrad />
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          <Spinner animation="border" variant="light"></Spinner>
        </div>
      )}
      <AnimationDiv
        style={{
          display: showParallax && !loading ? "flex" : "none",
        }}
      >
        <ImgContainer>
          <BgImgComp
            onLoad={() => setLoading(false)}
            animate={BgAnim}
            src={ParallaxBg}
            alt=""
          />
          <NomadImgComp animate={NomadAnim} src={StudioNomadImg} alt="" />
        </ImgContainer>
        <AnimatePresence>
          <CustomParallaxBanner
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duartion: 0.3 }}
            layers={[textBack, text2, left1, left2, right1, right2]}
          />
        </AnimatePresence>
        <FontAwesomeIcon
          style={{
            color: "var(--text-1-500)",
            position: "absolute",
            zIndex: 16,
            bottom: "50px",
          }}
          icon={faAnglesDown}
          size="2x"
        />
      </AnimationDiv>
    </ParallaxContainer>
  );
}

const BackgroundGrad = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  background-image: linear-gradient(to bottom right, #2b2b2b, #1b1c1c);
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ParallaxContainer = styled.div`
  transition: all 0.5s;
  height: 100vh;
`;

const AnimationDiv = styled.div`
  transition: opacity 0.7s;
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NomadImgComp = styled(motion.img)`
  width: 100%;
  z-index: 11;
  max-width: 400px;
  position: fixed;
  transition: all 0.1s;
  margin: 0 0 360px 0px;
  @media (max-width: 1500px) {
    max-width: 330px;
    margin: 0 0 350px 0;
  }
  @media (max-height: 1000px) {
    max-width: 230px;
    margin: 0 0 220px -20px;
  }
  @media (max-height: 700px) {
    max-width: 180px;
    margin: 0 0 190px -10px;
  }
  @media (max-width: 1024px) {
    max-width: 230px;
    margin: 0 0 220px -20px;
  }
  @media (max-width: 767px) {
    max-width: 180px;
    margin: 0 0 180px -10px;
  }
  @media (max-width: 528px) {
    max-width: 150px;
    margin: 0 0 140px -10px;
  }
  @media (max-width: 480px) {
    margin: 0 0 130px -10px;
  }
`;

const BgImgComp = styled(motion.img)`
  width: 100%;
  max-width: 1400px;
  z-index: 11;
  transition: all 0.1s;
  margin: 0 0 450px 0;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 1000px;
    margin: 0 0 350px 0;
  }
  @media (max-height: 1000px) {
    max-width: 700px;
    margin: 0 0 220px -20px;
  }
  @media (max-height: 700px) {
    max-width: 600px;
    margin: 0 0 190px -10px;
  }
  @media (max-width: 1024px) {
    max-width: 700px;
    margin: 0 0 220px -20px;
  }
  @media (max-width: 767px) {
    max-width: 600px;
    margin: 0 0 180px -10px;
  }
  @media (max-width: 528px) {
    max-width: 390px;
    margin: 0 0 140px -10px;
    @media (max-width: 480px) {
      margin: 0 0 130px -10px;
    }
  }
`;
const DestRight1ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 50%;
  right: -200px;
  bottom: -50px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 50%;
    right: -100px;
    bottom: -55px;
  }
  @media (max-width: 1024px) {
    right: -50px;
    bottom: -45px;
  }
  @media (max-width: 767px) {
    max-width: 60%;
    right: -50px;
    bottom: -35px;
  }
  @media (max-width: 528px) {
    max-width: 100%;
    right: -55px;
    bottom: -19px;
  }
`;

const DestRight2ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 70%;
  right: -200px;
  bottom: -70px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 70%;
    right: -100px;
    bottom: -55px;
  }
  @media (max-width: 1024px) {
    right: -50px;
    bottom: -45px;
  }
  @media (max-width: 767px) {
    max-width: 90%;
    right: -50px;
    bottom: -35px;
  }
  @media (max-width: 528px) {
    visibility: hidden;
  }
`;

const DestLeft1ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 50%;
  left: -200px;
  bottom: -50px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    left: -100px;
    bottom: -75px;
  }
  @media (max-width: 1024px) {
    left: -50px;
    bottom: -55px;
  }
  @media (max-width: 767px) {
    max-width: 60%;
    left: -55px;
    bottom: -55px;
  }
  @media (max-width: 528px) {
    max-width: 100%;
    left: -55px;
    bottom: -19px;
  }
`;
const DestLeft2ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 70%;
  left: -200px;
  bottom: -70px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 70%;
    left: -100px;
    bottom: -75px;
  }
  @media (max-width: 1024px) {
    max-width: 70%;
    left: -50px;
    bottom: -55px;
  }
  @media (max-width: 767px) {
    max-width: 90%;
    left: -55px;
    bottom: -55px;
  }
  @media (max-width: 528px) {
    visibility: hidden;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 1040px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-height: 1000px) {
    width: 790px;
  }
  @media (max-width: 1500px) {
    width: 1040px;
  }
  @media (max-height: 700px) {
    width: 790px;
  }
  @media (max-width: 1024px) {
    width: 790px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 500px) {
    width: 1040px;
  }
`;

const BarzahSolid = styled(motion.img)`
  width: 1000px;
  position: absolute;
  @media (max-height: 1000px) {
    width: 600px;
  }
  @media (max-width: 1500px) {
    width: 700px;
  }
  @media (max-height: 700px) {
    width: 500px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 528px) {
    width: 320px;
  }
  @media (max-width: 358px) {
    width: 250px;
  }
`;

const CustomLottie = styled(Lottie)`
  width: 1000px;
  @media (max-height: 1000px) {
    width: 600px;
  }
  @media (max-width: 1500px) {
    width: 700px;
  }
  @media (max-height: 700px) {
    width: 500px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 528px) {
    width: 320px;
  }
  @media (max-width: 358px) {
    width: 250px;
  }
`;

const CustomParallaxBanner = styled(ParallaxBanner)`
  [data-testid="layer-0"] {
    z-index: 10;
  }
  [data-testid="layer-1"] {
    z-index: 12;
  }
  [data-testid="layer-2"] {
    z-index: 15;
  }
  [data-testid="layer-3"] {
    z-index: 15;
  }
  [data-testid="layer-4"] {
    z-index: 15;
  }
  [data-testid="layer-5"] {
    z-index: 15;
  }
  width: 100%;
  height: 100%;
  overflow: visible !important;
`;
