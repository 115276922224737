import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import {
  ApexLogo,
  ArrowsBlack,
  ArrowsWhite,
  LinesBlack,
  LinesWhite,
  LolLogo,
  SlideImg,
  TekkenLogo,
  ValLogo,
  ValRedLogo,
} from "../../assets";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalPlayerCard from "./modalPlayerCard";

const modal = {
  hidden: { height: "0", transition: { duration: 1 } },
  visible: {
    height: "92%",
    transition: { duration: 1 },
  },
};

export const playerNews = [
  {
    id: 2,
    title: "Card Title",
    date: "30 Dec 2022",
    content:
      "<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><h1 style='font-size: 30px;'>Welcome to life</h1><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>",
    description:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    img: SlideImg,
  },
  {
    id: 3,
    title: "Card Title",
    date: "30 Dec 2022",
    content:
      "<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><h1 style='font-size: 30px;'>Welcome to life</h1><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>",
    description:
      " Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
    img: SlideImg,
  },
  {
    id: 4,
    title: "Card Title",
    date: "30 Dec 2022",
    content:
      "<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><h1 style='font-size: 30px;'>Welcome to life</h1><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p><br /><br /><p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>",
    description:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    img: SlideImg,
  },
];

const EsportsModal = ({ showModal, setShowModal, players, index }) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
  }, [showModal]);

  const handleClick = (e) => {
    const element = document.getElementById("modal-content");
    if (!element.contains(e.target)) {
      setShowModal(false);
    }
  };
  return (
    <AnimatePresence>
      {showModal && (
        <ModalDiv onMouseDown={handleClick}>
          {console.log(players[index])}
          <ModalCard
            id="modal-content"
            style={{
              background:
                players[index].logo === LolLogo ||
                players[index].logo === TekkenLogo
                  ? "var(--text-1-500)"
                  : "#1b1c1c",
              color:
                players[index].logo === LolLogo ||
                players[index].logo === TekkenLogo
                  ? "#1b1c1c"
                  : "var(--text-1-500)",
            }}
            variants={modal}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <PlayerCardCont>
              <CloseBtn
                logo={players[index].logo}
                icon={faClose}
                onClick={() => setShowModal(false)}
              />
              <ContentWrapper>
                <ArrowsImg
                  src={
                    players[index].logo === LolLogo ||
                    players[index].logo === TekkenLogo
                      ? ArrowsBlack
                      : ArrowsWhite
                  }
                  style={{
                    top: "50px",
                    right: "100px",
                    width:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "20px"
                        : "50px",
                    rotate:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "-90deg"
                        : "0deg",
                  }}
                />
                {/* <LinesImg
                  src={
                    players[index].logo === LolLogo ||
                    players[index].logo === TekkenLogo
                      ? LinesBlack
                      : LinesWhite
                  }
                  style={{
                    bottom: "-10px",
                    right: "100px",
                    rotate:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "-90deg"
                        : "-90deg",
                  }}
                /> */}
                <ArrowsImgLeft
                  src={
                    players[index].logo === LolLogo ||
                    players[index].logo === TekkenLogo
                      ? ArrowsBlack
                      : ArrowsWhite
                  }
                  style={{
                    bottom:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "30px"
                        : "50px",
                    left: "50px",
                    width:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "20px"
                        : "50px",
                    rotate:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "0deg"
                        : "90deg",
                  }}
                />
                <LinesImgLeft
                  src={
                    players[index].logo === LolLogo ||
                    players[index].logo === TekkenLogo
                      ? LinesBlack
                      : LinesWhite
                  }
                  style={{ top: "50px", left: "50px" }}
                />
                {/* <CustomFontAwesome
                  style={{
                    color:
                      players[index].logo === LolLogo ||
                      players[index].logo === TekkenLogo
                        ? "var(--text-2-500)"
                        : "var(--text-1-500)",
                  }}
                  icon={faAnglesDown}
                  size="2x"
                /> */}
                <FirstContentWrap>
                  <PlayerInfoWrapper>
                    <ModalPlayerCard
                      img={players[index].img}
                      color={
                        players[index].logo === LolLogo
                          ? "rgb(242,205,99)"
                          : players[index].logo === TekkenLogo
                          ? "rgb(254,84,22)"
                          : players[index].logo === ApexLogo
                          ? "rgb(255,58,57)"
                          : "rgb(254,70,85)"
                      }
                    />
                  </PlayerInfoWrapper>
                  <PlayerInfoWrap>
                    <InfoWrapper>
                      <LogoImg
                        src={
                          players[index].logo === ValLogo
                            ? ValRedLogo
                            : players[index].logo
                        }
                      />
                      <DescriptionWrapper>
                        <div>
                          <NickName>{players[index].nickname}</NickName>
                          <InfoText>{players[index].name}</InfoText>
                          <InfoText>{players[index].age}</InfoText>
                          <InfoText>{players[index].country}</InfoText>
                        </div>
                        <div>
                          {players[index].social.map((item) => (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              style={{ padding: "12px", paddingLeft: "0px" }}
                              href={item.link}
                            >
                              {item.icon}
                            </a>
                          ))}
                        </div>
                      </DescriptionWrapper>
                    </InfoWrapper>
                    <InfoDesc
                      dangerouslySetInnerHTML={{
                        __html: players[index].description,
                      }}
                    ></InfoDesc>
                  </PlayerInfoWrap>
                </FirstContentWrap>
                {/* <NewsCardCont>
                  <Row style={{ maxWidth: "1500px" }}>
                    {playerNews.map((item, idx) => (
                      <Col lg={6} key={item.id}>
                        <CustomCard
                          color={
                            players[index].logo === LolLogo ||
                            players[index].logo === TekkenLogo
                              ? "var(--text-2-500)"
                              : "var(--text-1-500)"
                          }
                          idx={idx}
                        >
                          <CustomLink href="https://google.com" target="_blank">
                            <ImageWrapper>
                              <CustomImage
                                // onLoad={handleLoad}
                                // id="card-img"
                                variant="top"
                                src={item.img}
                              />
                              <ImageDark />
                            </ImageWrapper>
                            <CustomCardBody
                              color={
                                players[index].logo === LolLogo ||
                                players[index].logo === TekkenLogo
                                  ? "var(--text-2-500)"
                                  : "var(--text-1-500)"
                              }
                            >
                              <CustomCardFooter
                                style={{
                                  color:
                                    players[index].logo === LolLogo ||
                                    players[index].logo === TekkenLogo
                                      ? "#6d6d6d"
                                      : "#a4a4a4",
                                }}
                              >
                                {item.date}
                              </CustomCardFooter>
                              <CustomCardTitle>{item.title}</CustomCardTitle>
                              <CustomCardText
                                color={
                                  players[index].logo === LolLogo ||
                                  players[index].logo === TekkenLogo
                                    ? "#6d6d6d"
                                    : "#a4a4a4"
                                }
                              >
                                {item.description}
                              </CustomCardText>
                            </CustomCardBody>
                          </CustomLink>
                        </CustomCard>
                      </Col>
                    ))}
                  </Row>
                </NewsCardCont> */}
              </ContentWrapper>
            </PlayerCardCont>
          </ModalCard>
        </ModalDiv>
      )}
    </AnimatePresence>
  );
};

const DescriptionWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const FirstContentWrap = styled.div`
  height: 100%;
  display: flex;
  padding-top: 20px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

const LinesImgLeft = styled.img`
  width: 30px;
  position: absolute;
  @media (max-width: 1230px) {
    display: none;
  }
  @media (max-height: 950px) {
    display: none;
  }
`;

const ArrowsImgLeft = styled.img`
  position: absolute;
  @media (max-width: 1230px) {
    display: none;
  }
  @media (max-height: 950px) {
    display: none;
  }
`;

// const LinesImg = styled.img`
//   width: 30px;
//   position: absolute;
//   @media (max-width: 1230px) {
//     display: none;
//   }
// `;

const ArrowsImg = styled.img`
  position: absolute;
  @media (max-width: 1230px) {
    display: none;
  }
`;

// const CustomFontAwesome = styled(FontAwesomeIcon)`
//   position: absolute;
//   margin-left: auto;
//   margin-right: auto;
//   left: 0;
//   right: 0;
//   bottom: 10px;
//   @media (max-width: 600px) {
//     font-size: 20px;
//   }
// `;

// const CustomLink = styled.a`
//   text-decoration: none;
//   color: var(--text-2-500);
// `;

// const NewsCardCont = styled.div`
//   width: 100%;
//   max-width: 1500px;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 50px;
//   grid-column-start: 1;
//   grid-column-end: 4;
//   display: flex;
//   position: relative;
//   justify-content: center;
//   align-items: center;
//   @media (max-width: 767px) {
//     padding: 20px;
//   }
// `;

// const CustomCardBody = styled(Card.Body)`
//   position: relative;
//   color: ${(props) => props.color};
// `;

// const CustomCardTitle = styled(Card.Title)`
//   font-size: 40px;
//   font-family: "Rubik";
//   font-weight: 700;
// `;

// const CustomCardText = styled(Card.Text)`
//   font-size: 20px;
//   font-family: "Space Mono";
//   color: ${(props) => props.color};
//   @media (max-width: 767px) {
//     display: none;
//   }
// `;

// const CustomCardFooter = styled.h1`
//   font-family: "Space Mono";
//   font-size: 15px;
//   background: none;
//   border: none;
//   rotate: -90deg;
//   position: absolute;
//   top: 50px;
//   left: -50px;
// `;

// const ImageDark = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 10;
// `;

// const ImageWrapper = styled.div`
//   overflow: hidden;
//   position: relative;
//   border-radius: 5px;
//   height: 0;
//   width: 100%;
//   padding-top: 56.25%;
// `;

// const CustomImage = styled(Card.Img)`
//   transform: scale(1);
//   position: absolute;
//   width: 100%;
//   top: 0;
//   left: 0;
//   transition: all 0.3s;
//   z-index: 1;
// `;

// const CustomCard = styled(Card)`
//   border: none;
//   position: relative;
//   margin-bottom: 50px;
//   ${({ idx }) =>
//     idx % 2 === 1
//       ? {
//           marginLeft: "25px",
//         }
//       : { marginRight: "25px" }}
//   background: none;
//   cursor: pointer;
//   @media (max-width: 1199px) {
//     margin-left: 0;
//     margin-right: 0;
//   }
//   &:hover ${CustomImage} {
//     transform: scale(1.2);
//   }
//   &:hover ${ImageDark} {
//     background: rgba(0, 0, 0, 0.3);
//   }
//   &:hover ${CustomCardTitle} {
//     color: ${(props) => props.color};
//     text-decoration: underline;
//     text-decoration-skip: spaces;
//   }
// `;

const PlayerInfoWrap = styled.div`
  display: flex;
  padding: 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  /* width: 100%; */
  /* justify-content: center; */
  flex-direction: column;
  @media (max-width: 1250px) {
    align-items: center;
    padding: 20px;
    gap: 10px;
    margin-bottom: auto;
    margin-right: 0;
  }
  /* @media (max-width: 1250px) {
    padding-bottom: 20px;
  } */
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  @media (max-width: 1250px) {
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 15px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  max-width: 700px;
  gap: 50px;
  align-items: center;
  @media (max-width: 1250px) {
    gap: 20px;
    text-align: center;
  }
`;

const InfoDesc = styled.p`
  font-size: 20px;
  padding-top: 20px;
  text-align: left;
  line-height: 1.5;
  max-width: 700px;
  font-family: "Space Mono";
  @media (max-height: 800px) and (max-width: 1250px) {
    font-size: 14px;
  }
  @media (max-width: 600px) {
    padding-top: 5px;
  }
`;

const PlayerCardCont = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayerInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 60px;
  /* width: 100%; */
  @media (max-width: 1250px) {
    /* padding: 0px 0px 40px 0; */
    margin-left: 0;
    padding-top: 30px;
    padding-right: 0;
  }
`;

const LogoImg = styled.img`
  width: 300px;
  @media (max-width: 1424px) {
    width: 300px;
  }
  @media (max-width: 1250px) {
    width: 200px;
  }
  @media (max-height: 800px) and (min-width: 1250px) {
    width: 190px;
  }
  @media (max-height: 800px) and (max-width: 1250px) {
    width: 120px;
  }
`;

const NickName = styled.h1`
  font-family: "Rubik";
  font-weight: 700;
  font-size: 25px;
  @media (max-height: 800px) and (max-width: 1250px) {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const InfoText = styled.p`
  font-size: 15px;
  font-family: "Space Mono";
  @media (max-height: 800px) and (max-width: 1250px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const ModalDiv = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const ModalCard = styled(motion.div)`
  background-color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #888;
  width: 94%;
`;

const CloseBtn = styled(FontAwesomeIcon)`
  color: #858484;
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 25px;
  z-index: 99999;
  &:hover {
    color: ${(props) =>
      props.logo === LolLogo || props.logo === TekkenLogo
        ? "var(--text-2-500)"
        : "var(--text-1-500)"};
    cursor: pointer;
  }
`;

export default EsportsModal;
