import { ParallaxBanner } from "react-scroll-parallax";
import {
  TrainingBgImg,
  TrainingCloudLeft,
  TrainingCloudRight,
  TrainingUeSolidImg,
} from "../../../assets";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { AnimatePresence, useAnimation, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import BorderAnim from "./animation_leolfezo.json";

export function TrainingParallaxBanner() {
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showParallax, setShowParallax] = useState(true);
  const DestLeft1Anim = useAnimation();
  const DestRight1Anim = useAnimation();
  const DestLeft2Anim = useAnimation();
  const DestRight2Anim = useAnimation();

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    const offsetFactor = 15;
    DestLeft1Anim.start({
      x: moveX / -offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestRight1Anim.start({
      x: moveX / offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestLeft2Anim.start({
      x: moveX / offsetFactor,
      y: moveY / -offsetFactor,
    });
    DestRight2Anim.start({
      x: moveX / -offsetFactor,
      y: moveY / -offsetFactor,
    });
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const TrainingSec2Top = document
      .getElementById("TrainingSec2")
      .getBoundingClientRect().top;
    const TrainingSec3Top = document
      .getElementById("TrainingSec3")
      .getBoundingClientRect().top;
    if (TrainingSec2Top <= windowHeight - 150) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
    if (TrainingSec3Top <= windowHeight - 500) {
      setShowParallax(false);
    } else {
      setShowParallax(true);
    }
  };
  useEffect(() => {
    setDisplay(true);
    var bgImg = new Image();
    bgImg.onload = function () {
      document.getElementById(
        "background-image-training"
      ).style.backgroundImage = "url(" + bgImg.src + ")";
      setLoading(false);
    };
    bgImg.src = TrainingBgImg;
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const textFront = {
    translateY: [0, -100],
    speed: -10,
    scale: [1, 1.2],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="center">
        {/* Border */}
        <TitleWrapper>
          <AnimatePresence>
            {display && !loading && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                style={{ color: "var(--text-1-500)" }}
              >
                <UE5Image src={TrainingUeSolidImg} />
              </motion.span>
            )}
          </AnimatePresence>
          {display && !loading && (
            <CustomLottie animationData={BorderAnim} loop={false} />
          )}
        </TitleWrapper>
      </div>
    ),
  };
  const left1 = {
    translateY: [0, -50],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestLeft1ImgComp
              animate={DestLeft1Anim}
              src={TrainingCloudLeft}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const left2 = {
    translateY: [0, -150],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestLeft2ImgComp
              animate={DestLeft2Anim}
              src={TrainingCloudLeft}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const right1 = {
    translateY: [0, -50],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestRight1ImgComp
              animate={DestRight1Anim}
              src={TrainingCloudRight}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  const right2 = {
    translateY: [0, -110],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <AnimatePresence>
        {display && !loading && (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className="center"
          >
            <DestRight2ImgComp
              animate={DestRight2Anim}
              src={TrainingCloudLeft}
              alt=""
            />
          </motion.div>
        )}
      </AnimatePresence>
    ),
  };
  return (
    <ParallaxContainer
      onMouseMove={handleMouseMove}
      id="background-image-training"
      style={{
        opacity: display ? 1 : 0,
      }}
    >
      {showParallax && <BackgroundGrad />}
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          <Spinner animation="border" variant="light"></Spinner>
        </div>
      )}
      <AnimationDiv
        style={{
          display: showParallax && !loading ? "flex" : "none",
        }}
      >
        <AnimatePresence>
          <CustomParallaxBanner
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duartion: 0.3 }}
            layers={[textFront, left1, left2, right1, right2]}
          />
        </AnimatePresence>
        <FontAwesomeIcon
          style={{
            color: "var(--text-1-500)",
            position: "absolute",
            bottom: "50px",
            zIndex: 16,
          }}
          icon={faAnglesDown}
          size="2x"
        />
      </AnimationDiv>
    </ParallaxContainer>
  );
}
const BackgroundGrad = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
  position: absolute;
  background-image: linear-gradient(rgba(27, 28, 28, 0), rgba(27, 28, 28, 1));
`;

const CustomLottie = styled(Lottie)`
  width: 1000px;
  @media (max-height: 1000px) {
    width: 800px;
  }
  @media (max-width: 1500px) {
    width: 800px;
  }
  @media (max-height: 700px) {
    width: 600px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 600px) {
    width: 320px;
  }
  @media (max-width: 420px) {
    width: 250px;
  }
`;

const UE5Image = styled.img`
  height: 140px;
  @media (max-height: 1000px) {
    height: 120px;
  }
  @media (max-width: 1500px) {
    height: 120px;
  }
  @media (max-height: 700px) {
    height: 90px;
  }
  @media (max-width: 1024px) {
    height: 90px;
  }
  @media (max-width: 767px) {
    height: 70px;
  }
  @media (max-width: 600px) {
    height: 47px;
  }
  @media (max-width: 420px) {
    height: 37px;
  }
`;

const ParallaxContainer = styled.div`
  transition: all 0.5s;
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AnimationDiv = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const DestRight1ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 50%;
  right: -200px;
  bottom: -70px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 50%;
    right: -100px;
    bottom: -55px;
  }
  @media (max-width: 1024px) {
    right: -50px;
    bottom: -45px;
  }
  @media (max-width: 767px) {
    max-width: 60%;
    right: -50px;
    bottom: -35px;
  }
  @media (max-width: 528px) {
    max-width: 100%;
    right: -55px;
    bottom: -35px;
  }
  @media (max-width: 528px) {
    visibility: hidden;
  }
`;

const DestRight2ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 70%;
  right: -200px;
  bottom: -90px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 70%;
    right: -100px;
    bottom: -55px;
  }
  @media (max-width: 1024px) {
    right: -50px;
    bottom: -45px;
  }
  @media (max-width: 767px) {
    max-width: 90%;
    right: -50px;
    bottom: -35px;
  }
`;

const DestLeft1ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 50%;
  left: -200px;
  bottom: -70px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    left: -100px;
    bottom: -75px;
  }
  @media (max-width: 1024px) {
    left: -50px;
    bottom: -55px;
  }
  @media (max-width: 767px) {
    max-width: 60%;
    left: -55px;
    bottom: -55px;
  }
  @media (max-width: 528px) {
    max-width: 100%;
    left: -55px;
    bottom: -35px;
  }
`;
const DestLeft2ImgComp = styled(motion.img)`
  width: 100%;
  max-width: 70%;
  left: -200px;
  bottom: -120px;
  z-index: 11;
  transition: all 0.1s;
  position: fixed;
  @media (max-width: 1500px) {
    max-width: 70%;
    left: -100px;
    bottom: -75px;
  }
  @media (max-width: 1024px) {
    max-width: 70%;
    left: -50px;
    bottom: -55px;
  }
  @media (max-width: 767px) {
    max-width: 90%;
    left: -55px;
    bottom: -55px;
  }
  @media (max-width: 528px) {
    visibility: hidden;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 1040px;
  height: 100%;
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 1500px) {
    width: 1040px;
    gap: 30px;
  }
  @media (max-width: 1024px) {
    width: 790px;
    gap: 20px;
  }
  @media (max-width: 767px) {
    width: 540px;
    gap: 15px;
  }
  @media (max-width: 600px) {
    width: 500px;
  }
  @media (max-width: 420px) {
    gap: 10px;
  }
`;

const CustomParallaxBanner = styled(ParallaxBanner)`
  [data-testid="layer-0"] {
    z-index: 10;
  }
  [data-testid="layer-1"] {
    z-index: 15;
  }
  [data-testid="layer-2"] {
    z-index: 15;
  }
  [data-testid="layer-3"] {
    z-index: 15;
  }
  [data-testid="layer-4"] {
    z-index: 15;
  }
  width: 100%;
  height: 100%;
  overflow: visible !important;
`;
