import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowsBlack, LinesBlack, StudioNomadImg } from "../../assets";

const StudioNomad = () => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const StudioSec3Top = document
      .getElementById("StudioSec3")
      .getBoundingClientRect().top;
    if (StudioSec3Top <= windowHeight - 320) {
      setLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <EsportHomeCont id={"StudioSec3"}>
      <TitleWrapper>
        <GameDev>
          <DoubleText
            style={{
              WebkitTextStroke: "1px rgb(255, 255, 255)",
              left: logo ? "0px" : "20%",
              opacity: logo ? 1 : 0,
            }}
          >
            NOMAD
          </DoubleText>
        </GameDev>
      </TitleWrapper>
      <InfoContainer>
        <TitleWrapper style={{ position: "absolute", top: 0 }}>
          <GameDev>
            <DoubleText
              style={{
                WebkitTextStroke: "1px rgb(0, 0, 0)",
                left: logo ? "0px" : "20%",
                opacity: logo ? 1 : 0,
              }}
            >
              NOMAD
            </DoubleText>
          </GameDev>
        </TitleWrapper>
        <ArrowWrapper>
          <ArrowImg src={ArrowsBlack} />
          <LinesImg src={LinesBlack} />
        </ArrowWrapper>
        <InfoWrapper>
          <TitleText>Project J’s first playable class.</TitleText>
          <DescText>
          NOMADs are a specialized group of highly skilled hunters who possess exceptional 
abilities in tracking and hunting supernatural beings. Their lineage can be traced 
back to a long line of Emirati Bedouin warriors who have been honing their skills for 
generations. Their skills, knowledge, and heritage make them a force to be 
reckoned with, and they continue to serve as the first line of defense against the 
unknown. 
          </DescText>
        </InfoWrapper>
        <ImageWrapper>
          <NomadBorder>Nomad</NomadBorder>
          <NomadSolid>Nomad</NomadSolid>
          <CustomImg src={StudioNomadImg} />
        </ImageWrapper>
      </InfoContainer>
    </EsportHomeCont>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GameDev = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 920px;
  @media (max-width: 1500px) {
    width: 670px;
  }
  @media (max-width: 1024px) {
    width: 490px;
  }
  @media (max-width: 767px) {
    width: 310px;
  }
  @media (max-width: 500px) {
    width: 230px;
  }
`;

const DoubleText = styled.h2`
  transition: all 0.7s;
  font-size: 250px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  letter-spacing: 3px;
  top: -122px;
  font-weight: 700;
  position: absolute;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 180px;
    top: -90px;
  }
  @media (max-width: 1024px) {
    font-size: 130px;
    top: -65px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
    top: -40px;
  }
  @media (max-width: 500px) {
    font-size: 60px;
    top: -29px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const NomadBorder = styled.h2`
  font-size: 140px;
  text-transform: uppercase;
  font-family: Rubik;
  rotate: 90deg;
  z-index: 13;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 700;
  position: absolute;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px var(--text-1-500);
  visibility: visible;
  right: -150px;
  top: 350px;
  border-top-left-radius: 1px;
  @media (max-width: 2060px) {
    font-size: 120px;
    top: 300px;
    right: -120px;
  }
  @media (max-width: 1530px) {
    font-size: 100px;
    top: 280px;
    right: -100px;
  }
  @media (max-width: 600px) {
    font-size: 65px;
    right: -85px;
    top: 110px;
  }
`;

const NomadSolid = styled.h2`
  color: var(--main-600);
  font-size: 140px;
  rotate: 90deg;
  z-index: 11;
  right: -150px;
  top: 350px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  letter-spacing: 3px;
  position: absolute;
  font-weight: 700;
  border-top-left-radius: 1px;
  @media (max-width: 2060px) {
    font-size: 120px;
    top: 300px;
    right: -120px;
  }
  @media (max-width: 1530px) {
    font-size: 100px;
    top: 280px;
    right: -100px;
  }
  @media (max-width: 600px) {
    font-size: 65px;
    right: -85px;
    top: 110px;
  }
`;

const LinesImg = styled.img`
  width: 30px;
  rotate: -90deg;
  position: absolute;
  left: -10px;
  top: -400px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 500px;
  top: 0;
  width: 1300px;
  height: 500px;
  @media (max-width: 2060px) {
    height: 450px;
  }
  @media (max-width: 1530px) {
    width: 1100px;
    height: 350px;
  }
  @media (max-width: 1350px) {
    width: 900px;
  }
  @media (max-width: 1145px) {
    width: 700px;
    height: 350px;
  }
  @media (max-width: 930px) {
    display: none;
  }
`;

const ArrowImg = styled.img`
  position: absolute;
  right: -50px;
  rotate: -90deg;
  width: 20px;
  bottom: 40px;
`;

const CustomImg = styled.img`
  position: relative;
  z-index: 12;
  height: 750px;
  @media (max-width: 2060px) {
    height: 700px;
  }
  @media (max-width: 1530px) {
    height: 600px;
  }
  @media (max-width: 600px) {
    height: 300px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 150px 0px 150px 0px;
  background: #eff4f3;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1160px) {
    flex-direction: column-reverse;
    gap: 50px;
    text-align: center;
  }
  @media (max-width: 600px) {
    padding: 50px 0px 50px 0px;
  }
`;

const InfoWrapper = styled.div`
  color: var(--text-2-500);
  width: 100%;
  padding: 20px;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1350px) {
    align-items: center;
    justify-content: center;
    max-width: 500px;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  font-family: "Rubik";
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 1350px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DescText = styled.p`
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 1350px) {
    font-size: 15px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const EsportHomeCont = styled.div`
  background: var(--main-600);
  color: var(--text-2-500);
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 50px 0px 0px 0px;
  position: relative;
`;

export default StudioNomad;
