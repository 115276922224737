import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowsWhite, LinesWhite } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const EsportsDescription = () => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const EsportsSec2Top = document
      .getElementById("EsportsSec2")
      .getBoundingClientRect().top;
    if (EsportsSec2Top > windowHeight - 150) {
      setLogo(false);
    } else {
      setLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <EsportHomeCont
      style={{
        opacity: logo ? 1 : 0,
        transitionDelay: logo ? "0.5s" : "0s",
      }}
      id={"EsportsSec2"}
    >
      <InfoContainer>
        <ArrowWrapper>
          <ArrowImg src={ArrowsWhite} />
          <LinesImg src={LinesWhite} />
        </ArrowWrapper>
        <InfoWrapper>
          <TitleText>WHERE PASSION MEETS SKILL</TitleText>
          <DescText>
            Our objective is to cultivate excellence among team members by
            providing access to the region's premier coaches and facilitating
            participation in competitive events, guided by a management team of
            experienced veteran Emirati E-sport athletes who maintain their
            competitive edge and bring extensive industry experience to our
            perpetual mission of recruiting and empowering the youth in the
            competitive arena.
          </DescText>
        </InfoWrapper>
        <SocialWrapper>
          <a
            href="https://twitter.com/barzah_esport"
            target="_blank"
            rel="noreferrer"
          >
            <SocialImg icon={faTwitter} />
          </a>
          <a
            href="https://www.instagram.com/barzahesport"
            target="_blank"
            rel="noreferrer"
          >
            <SocialImg icon={faInstagram} />
          </a>
        </SocialWrapper>
      </InfoContainer>
    </EsportHomeCont>
  );
};

const SocialImg = styled(FontAwesomeIcon)`
  color: #ed2020;
  font-size: 50px;
  width: 60px;
  @media (max-width: 900px) {
    font-size: 30px;
    width: 40px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    width: 30px;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  z-index: 99999;
`;

const LinesImg = styled.img`
  width: 30px;
  rotate: -90deg;
  position: absolute;
  left: -10px;
  top: -300px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  margin-top: 100px;
  width: 1300px;
  height: 500px;
  @media (max-width: 1780px) {
    width: 1100px;
    height: 370px;
  }
  @media (max-width: 1350px) {
    width: 900px;
  }
  @media (max-width: 1150px) {
    width: 800px;
  }
  @media (max-width: 1070px) {
    width: 700px;
    height: 210px;
    margin-top: 140px;
  }
  @media (max-width: 930px) {
    display: none;
  }
`;

const ArrowImg = styled.img`
  position: absolute;
  right: -50px;
  rotate: -90deg;
  width: 55px;
  bottom: 40px;
`;

// const CustomImg = styled.img`
//   width: 900px;
//   @media (max-width: 1780px) {
//     width: 600px;
//   }
//   @media (max-width: 1070px) {
//     width: 400px;
//   }
//   @media (max-width: 600px) {
//     width: 250px;
//   }
// `;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 100px;
  align-items: center;
  padding: 0px 30px;
  object-fit: cover;
  justify-content: center;
  @media (max-width: 1350px) {
    gap: 50px;
  }
  @media (max-width: 930px) {
    text-align: center;
  }
  @media (max-width: 600px) {
    padding: 0px 5px;
  }
`;

const InfoWrapper = styled.div`
  color: var(--text-1-500);
  width: 100%;
  padding: 20px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1350px) {
    align-items: center;
    justify-content: center;
    max-width: 500px;
  }
`;

const TitleText = styled.h1`
  font-size: 40px;
  font-family: "Rubik";
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 1780px) {
    font-size: 34px;
  }
  @media (max-width: 1350px) {
    font-size: 30px;
  }
  @media (max-width: 1230px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DescText = styled.p`
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
  font-family: "Space Mono";
  @media (max-width: 1780px) {
    font-size: 17px;
  }
  @media (max-width: 1350px) {
    font-size: 15px;
  }
  @media (max-width: 1230px) {
    font-size: 12px;
  }
`;

const EsportHomeCont = styled.div`
  color: var(--text-1-500);
  z-index: 10;
  height: 100%;
  position: relative;
  transition: opacity 0.5s;
`;

export default EsportsDescription;
