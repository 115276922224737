import React from "react";
import styled from "styled-components";

const GrayLines = ({ color, leftLine, left, top }) => {
  return (
    <CustomSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      color={color}
      left={left}
      top={top}
    >
      <path
        leftLine={leftLine}
        d={leftLine ? 'M0 0 L-200 -200' : 'M0 0 L200 -200'}
        fill="none"
      />
    </CustomSvg>
  );
};

const CustomSvg = styled.svg`
  width: 80px;
  position: absolute;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  overflow: visible;
  stroke: ${(props) => props.color};
  stroke-width: 25;
`;

export default GrayLines;
