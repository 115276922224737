import React, { useState } from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import axiosPrivate from "../../axios";
import ReCAPTCHA from "react-google-recaptcha";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

export const ContactEmail = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const sendEmail = async (e) => {
    setError(null);
    try {
      await axiosPrivate.post("/contact-email", e);
      setSent(true);
    } catch (err) {
      setError(err);
    }
  };
  return (
    <FormContainer>
      <AnimatePresence>
        {sent ? (
          <FormTitle
            initial={{ opacity: 0, x: 1000 }}
            animate={{ opacity: 1, x: 0 }}
            key={1}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{
              WebkitTextStroke: "1px rgb(0, 0, 0)",
              position: "absolute",
            }}
          >
            <span style={{ color: "var(--text-2-500)" }}>We will </span> get in{" "}
            <span style={{ color: "var(--text-2-500)" }}>touch with you soon</span>
          </FormTitle>
        ) : (
          <motion.div
            style={{ background: "var(--text-1-500)" }}
            initial={{ opacity: 1 }}
            key={2}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <FormTitle
              style={{
                WebkitTextStroke: "1px rgb(0, 0, 0)",
              }}
            >
              <span style={{ color: "var(--text-2-500)" }}>Get </span>in{" "}
              <span style={{ color: "var(--text-2-500)" }}>touch</span>
            </FormTitle>
            <FormWrapper>
              <Form
                style={{ maxWidth: "500px", width: "100%" }}
                onSubmit={handleSubmit(sendEmail)}
              >
                <Form.Group className="mb-3" controlId="formEmail">
                  <CustomLabel>First name</CustomLabel>
                  <CustomFormControl
                    style={{
                      borderRadius: 0,
                    }}
                    {...register("fullName", {
                      required: "Your full name is required",
                      maxLength: 100,
                    })}
                    isInvalid={errors?.fullName}
                    type="text"
                    placeholder="Enter full name..."
                  />
                  {errors.fullName && (
                    <h1 style={{ color: "red", paddingTop: "5px" }}>
                      {errors?.fullName?.message}
                    </h1>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <CustomLabel>Email address</CustomLabel>
                  <CustomFormControl
                    style={{
                      borderRadius: 0,
                    }}
                    {...register("email", {
                      required: "Your email address is required",
                      maxLength: 100,
                    })}
                    isInvalid={errors?.email}
                    type="text"
                    placeholder="Enter email..."
                  />
                  {errors.email && (
                    <h1 style={{ color: "red", paddingTop: "5px" }}>
                      {errors?.email?.message}
                    </h1>
                  )}
                </Form.Group>
                <Form.Group className="mb-4">
                  <CustomLabel>Your query</CustomLabel>
                  <CustomTextArea
                    className="form-control"
                    style={{
                      background: "none",
                      borderRadius: 0,
                    }}
                    as="textarea"
                    maxLength={500}
                    rows={2}
                    {...register("comment", {
                      required: "Your query is required",
                      maxLength: 500,
                    })}
                    placeholder="Enter your query..."
                  />
                  {errors.comment && (
                    <h1 style={{ color: "red", paddingTop: "5px" }}>
                      {errors?.comment?.message}
                    </h1>
                  )}
                </Form.Group>
                <Controller
                  control={control}
                  name="captcha"
                  render={({ field: { onChange } }) => (
                    <CustomReCAPTCHA
                      sitekey="6LfS4BMkAAAAACyfn5IndultQbt5hnCiLd5vkfkW"
                      onChange={onChange}
                    />
                  )}
                />
                {error && (
                  <h1 style={{ color: "red", paddingBottom: "15px" }}>
                    Please solve the captcha
                  </h1>
                )}
                <CustomButton variant="primary" type="htmlSubmit">
                  Submit
                </CustomButton>
              </Form>
            </FormWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </FormContainer>
  );
};

const FormTitle = styled(motion.h2)`
  font-size: 40px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  width: 600px;
  letter-spacing: 3px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1350px) {
    font-size: 30px;
	width: 400px;
  }
  @media (max-width: 1100px) {
    font-size: 20px;
	width: 350px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
	width: 400px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
	width: 350px;
  }
`;

const CustomReCAPTCHA = styled(ReCAPTCHA)`
  padding-bottom: 25px;
  @media (max-width: 900px) {
    padding-bottom: 0;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
  @media (max-width: 600px) {
    transform: scale(0.6);
    & > div > div {
      height: 60px !important;
    }
  }
`;

const CustomLabel = styled(Form.Label)`
  font-family: "Space Mono";
  @media (max-width: 900px) {
    display: none;
  }
`;

const CustomTextArea = styled(Form.Control)`
  font-family: "Space Mono";
  color: var(--text-2-500);
  border: 1px solid var(--text-2-500);
  resize: none;
  background-color: var(--text-1-500);
  &::placeholder {
    color: var(--text-3-500) !important;
    opacity: 1;
  }
`;

const CustomButton = styled.button`
  color: var(--text-1-500);
  background-color: transparent;
  border: 1px solid var(--text-1-500);
  width: 100%;
  padding: 10px 20px 12px calc(20px - 0.02em);
  justify-content: center;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.02em;
  font-family: Rubik, sans-serif;
  font-weight: 700;
  line-height: calc(30 / 17);
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  mix-blend-mode: difference;
  margin: 0;
  isolation: isolate;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  &:hover::before {
    clip-path: inset(0 0 0 0);
  }
  &::after {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: 5px;
    background-color: currentColor;
    position: absolute;
    bottom: 3px;
    left: 3px;
    pointer-events: none;
  }
  &::before {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px - 4px);
    background-color: currentColor;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96),
      -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    pointer-events: none;
    mix-blend-mode: difference;
    -webkit-clip-path: inset(100% 0 0 0);
    clip-path: inset(100% 0 0 0);
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
`;

const CustomFormControl = styled(Form.Control)`
  color: var(--text-2-500);
  border: 1px solid var(--text-2-500);
  background-color: var(--text-1-500);
  font-family: "Space Mono";
  &::placeholder {
    color: var(--text-3-500) !important;
    opacity: 1;
  }
`;

const FormContainer = styled.div`
  display: flex;
  color: var(--text-2-500);
  padding: 50px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  @media (max-width: 900px) {
    padding: 20px 0;
  }
  @media (max-width: 767px) {
    padding: 20px 0 0 0;
  }
  @media (max-height: 770px) {
    padding: 20px 0 0 0;
  }
`;
