import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import {
  ParallaxDesertDestLeft,
  ParallaxDesertDest,
  HomeEsportImg,
  HomeStudioImg,
  HomeTrainingImg,
} from "../../assets";
import { HomeParallaxBanner } from "../../components/parallaxBanner/homeParallax";
import HomeClipArt from "../../components/homeClipArt";
import HomeEsport from "../../components/homeEsport";
import HomeTraining from "../../components/homeTraining";
import HomeCarousel from "../../components/homeCarousel";
import CompaniesTrust from "../../components/companiesTrust";

const svgVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
  exit: { opacity: 0 },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      delay: 0.4,
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    pathLength: 0,
  },
};

const HomePage = ({ setSelected }) => {
  const [display, setDisplay] = useState(false);
  // const [logo, setLogo] = useState(false);
  const [imgAnim, setImgAnim] = useState(false);
  const [widt, setWidt] = useState(0);
  const [heit, setHeit] = useState(0);

  const handleResize = () => {
    setWidt(document.getElementById("text-div").offsetWidth * 1);
    setHeit(document.getElementById("text-div").offsetHeight * 1);
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const HomeSec2Top = document
      .getElementById("HomeSec2")
      .getBoundingClientRect().top;
    // const HomeSec3Top = document
    //   .getElementById("HomeSec3")
    //   .getBoundingClientRect().top;
    if (HomeSec2Top > windowHeight - 150) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
    if (HomeSec2Top > windowHeight - 400) {
      setImgAnim(false);
    } else {
      setImgAnim(true);
    }
    // if (HomeSec3Top > windowHeight - 200) {
    //   setLogo(false);
    // } else {
    //   setLogo(true);
    // }
  };

  useEffect(() => {
    setSelected(1);
    setTimeout(() => {
      setWidt(document.getElementById("text-div").offsetWidth * 1);
      setHeit(document.getElementById("text-div").offsetHeight * 1);
    }, 300);
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setSelected, setWidt, setHeit]);
  return (
    <div style={{ overflow: "hidden" }}>
      <HomeParallaxBanner />
      <DescriptionWrapper>
        <DescriptionContainer id="HomeSec2">
          <AnimatePresence>
            {imgAnim && (
              <>
                <RingImgStyled
                  transition={{ delay: 0.3, duration: 1 }}
                  initial={{ opacity: 0, x: -100, y: 0 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  exit={{ opacity: 0, x: -100, y: 0 }}
                  src={ParallaxDesertDest}
                />
                <TriangleImgStyled
                  transition={{ delay: 0.3, duration: 1 }}
                  initial={{ opacity: 0, x: 100, y: 0 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  exit={{ opacity: 0, x: 100, y: 0 }}
                  src={ParallaxDesertDestLeft}
                />
              </>
            )}
          </AnimatePresence>
          <InfoWrapper
            id="text-div"
            style={{
              opacity: display ? 1 : 0,
              transitionDelay: display ? "0.5s" : "0s",
            }}
          >
            <AnimatePresence>
              {display && (
                <CustomSvg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  variants={svgVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <motion.path
                    fill="none"
                    d={`M0 0 L${widt + widt / 5} 0 L${widt + widt / 3.2} ${
                      widt / 9
                    } L${widt + widt / 3.2} ${heit + heit / 3.9} L0 ${
                      heit + heit / 3.9
                    } L0 0Z`}
                    variants={pathVariants}
                  />
                </CustomSvg>
              )}
            </AnimatePresence>
            <Quotes
              style={{ height: display ? "40px" : "0px", overflow: "hidden" }}
            >
              "
            </Quotes>
            <DescriptionTitle>
              Barzah Studio is a gaming development company based in the United
              Arab Emirates
            </DescriptionTitle>
            <ExtraInfo>
            At Barzah Studio, our mission is to deliver innovative and immersive gaming 
experiences that truly engage our audience. We are dedicated to nurture the future 
of game development by offering extensive training programs focused on Unreal 
Engine 5, facilitating the ongoing progress of the gaming community. As passionate 
proprietors of esports teams, our commitment extends to promoting the growth and
evolution of the gaming E-sport industry throughout our region.
            </ExtraInfo>
          </InfoWrapper>
        </DescriptionContainer>
      </DescriptionWrapper>
      <HomeSecWrapper>
        <HomeClipArt
          title="a place Where Innovation and Creativity Come to Life"
          description="Leveraging the advanced technology of Unreal Engine 5, the team at Barzah Studio is proudly developing Project J with the goal of delivering a highly immersive gaming experience for players."
          section="Studio"
          img={HomeStudioImg}
          btn={false}
        />
        {/* <HomeEsport
        title="CURENTLY BARZAH WORKING ON
		LOREM IPSUMLAPTI WKSA
		SUITO QUEDRO LAPTI WKSA"
        description="Project J is (few words about project J)Lorem ipsum
		dolor sit amet, consecteturnullam ac tincidunt lacus
		efficitur suscipit tortor."
        section="Studio"
        img={SquerImg}
        color={true}
      /> */}
        <HomeCarousel />
        <HomeEsport
          title="Barzah Esports"
          description="Our objective is to foster excellence among all team members through access to the region's top coaches and participation in competitive events across the region."
          img={HomeEsportImg}
          color={false}
          section="Esports"
        />
        <HomeTraining
          title="Unleash Your Creativity with Unreal Engine 5"
          description="Our mission is to equip the future generation of game developers in the region with the necessary skills and tools through the delivery of comprehensive Unreal Engine 5 training programs."
          section="Training"
          btn={true}
          img={HomeTrainingImg}
        />
        <CompaniesTrust />
      </HomeSecWrapper>
    </div>
  );
};

const HomeSecWrapper = styled.div`
  transition: all 0.7s;
`;

const DescriptionWrapper = styled.div`
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  font-family: "Space Mono";
`;

const Quotes = styled.h1`
  font-size: 100px;
  position: absolute;
  transition: height 2s;
  font-family: Jost;
  top: -20%;
  right: 4%;
  @media (max-width: 990px) {
    font-size: 80px;
  }
  @media (max-width: 615px) {
    font-size: 50px;
  }
`;

const CustomSvg = styled(motion.svg)`
  width: 80px;
  position: absolute;
  left: -3%;
  top: -3%;
  overflow: visible;
  stroke: var(--text-1-500);
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
`;

const ExtraInfo = styled.h1`
  font-size: 25px;
  line-height: 1.5;
  padding: 10px 10px 30px 10px;
  position: relative;
  text-align: left;
  line-height: 1.5;
  @media (max-width: 990px) {
    font-size: 15px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const DescriptionTitle = styled.h1`
  font-size: 40px;
  font-family: Rubik;
  font-weight: 700;
  padding: 10px;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 990px) {
    font-size: 25px;
  }
  @media (max-width: 615px) {
    font-size: 22px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s;
  width: 690px;
  @media (max-width: 990px) {
    width: 330px;
  }
  @media (max-width: 615px) {
    width: 290px;
  }
`;

// const DescriptionInfo = styled.h3`
//   font-size: 20px;
//   padding: 10px 10px 30px 10px;
//   position: relative;
//   text-align: center;
//   @media (max-width: 990px) {
//     font-size: 15px;
//   }
//   @media (max-width: 550px) {
//     font-size: 8px;
//   }

//   &::after {
//     background-color: #fffade;
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     width: ${(props) => (props.widt ? "24px" : "0px")};
//     height: 7px;
//     margin: 100px 0 0 -12px;
//     transition: all 0.72s cubic-bezier(0.52, 0.01, 0.16, 1);
//     transform: skewY(-16deg) scaleX(1);
//     transform-origin: 0 100%;
//   }
// `;

const DescriptionContainer = styled.div`
  color: var(--text-1-500);
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const RingImgStyled = styled(motion.img)`
  left: -130px;
  bottom: -280px;
  width: 60%;
  position: absolute;
  rotate: 10deg;
  @media (max-width: 1500px) {
    width: 80%;
    left: -70px;
    bottom: -240px;
    rotate: 12deg;
  }
  @media (max-width: 1024px) {
    width: 100%;
    left: -70px;
    bottom: -240px;
    rotate: 18deg;
  }
  @media (max-width: 990px) {
    width: 100%;
    left: -70px;
    bottom: -210px;
    rotate: 15deg;
  }
  @media (max-width: 800px) {
    width: 100%;
    left: -70px;
    bottom: -200px;
    rotate: 18deg;
  }
  @media (max-width: 767px) {
    width: 200%;
    left: -150px;
    bottom: -250px;
    rotate: 15deg;
  }
  @media (max-width: 500px)
  {
    width: 200%;
    left: -150px;
    bottom: -190px;
    rotate: 15deg;
  }
  @media (max-width: 480px) {
    width: 200%;
    left: -150px;
    bottom: -160px;
    rotate: 15deg;
  }
`;

const TriangleImgStyled = styled(motion.img)`
  right: -130px;
  bottom: -280px;
  width: 60%;
  position: absolute;
  rotate: -10deg;
  @media (max-width: 1500px) {
    width: 80%;
    right: -70px;
    bottom: -240px;
    rotate: -12deg;
  }
  @media (max-width: 1024px) {
    width: 100%;
    right: -70px;
    bottom: -240px;
    rotate: -18deg;
  }
  @media (max-width: 990px) {
    width: 100%;
    right: -70px;
    bottom: -210px;
    rotate: -15deg;
  }
  @media (max-width: 800px) {
    width: 100%;
    right: -70px;
    bottom: -200px;
    rotate: -18deg;
  }
  @media (max-width: 767px) {
    width: 200%;
    right: -150px;
    bottom: -250px;
    rotate: -15deg;
  }
  @media (max-width: 500px)
  {
    width: 200%;
    right: -150px;
    bottom: -190px;
    rotate: -15deg;
  }
  @media (max-width: 480px) {
    width: 200%;
    right: -150px;
    bottom: -160px;
    rotate: -15deg;
  }
`;

export default HomePage;
