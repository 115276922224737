import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  LolLogo,
  TechLolLeft,
  MokiLolImg,
  JaysaImg,
  UnknownPlayerImg,
} from "../../assets";
import ComingSoonEsports from "../../components/comingSoonEsports";
import EsportsDescription from "../../components/esportsDescription";
import EsportsModal from "../../components/esportsModal";
import EsportsPlayers from "../../components/esportsPlayers";
import { EsportsParallaxBanner } from "../../components/parallaxBanner/esportsParallax";


const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 2em;
  @media (max-width: 768px)
  {
    font-size: 1.4em;
  }
`

// const valPlayers = [
//   {
//     nickname: "W1nner ",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: ValLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: ValLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: ValLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: ValLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: ValLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     description:
//       "A professional Valorant player, who started playing Valorant since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     age: "25 years",
//     country: "Ukraine",
//     img: GplayerImg,
//     logo: ValLogo,
//   },
// ];

const lolPlayers = [
  {
    nickname: "Moki",
    name: "Amna Alameri",
    country: "UAE",
    social: [
      {
        icon: <CustomFontAwesomeIcon icon={faTwitter} color="#ed1b1b" />,
        link: "https://twitter.com/iiMoki",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faInstagram} color="#ed1b1b" />,
        link: "https://instagram.com/amoona_evil",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faTwitch} color="#ed1b1b" />,
        link: "https://twitch.tv/iiMoki",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faTiktok} color="#ed1b1b" />,
        link: "https://tiktok.com/@iimoki",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faYoutube} color="#ed1b1b" />,
        link: "https://youtube.com/channel/UCkyz89yOJn6eOI-QUxjWtHw",
      },
    ],
    description:"I’m a multi-talented individual with a passion for art, technology, and gaming. As a self-taught artist and a software engineer, I’m always eager to learn and try new things. When I’m not busy playing professionally in the world of esports or streaming, I enjoy exploring new cultures and cuisines. Additionally, I own an online store called Moki Store(moki.ae), where i offer a variety of merchandise and reflect my entrepreneurial spirit.<br><br>I started playing video games at a young age and have always had passion for gaming. When I discovered league of legends, I found that I enjoyed the competitive aspect of the game. This led me to pursue a career as a professional League of Legend player.<br><br>The highest rank I reached in League of Legend was Platinum 4 as a Toplaner.",
    img: MokiLolImg,
    logo: LolLogo,
  },
  {
    nickname: "Hershey",
    name: "Sandra Majda",
    country: "Poland",
    social: [],
    description:"Ever since my childhood, an unquenchable fire of competitiveness has burned within me, driving my relentless pursuit of excellence in every activity I undertake. With unwavering determination, I continuously push the boundaries of my capabilities, striving to transcend my limits and achieve unparalleled mastery in every activity that captivates my passion.<br><br>In League of Legends the fire of competitiveness burns brightest in me, that’s why I want to reach the highest echelon of competitiveness in the game!<br><br>The highest rank I reached in League of Legends was Master as a Support",
    img: UnknownPlayerImg,
    logo: LolLogo,
  },
  {
    nickname: "DAS",
    name: "Dalia Shaeer",
    country: "Egypt",
    social: [
      {
        icon: <CustomFontAwesomeIcon icon={faInstagram} color="#ed1b1b" />,
        link: "https://www.instagram.com/dalia.das/",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faTwitter} color="#ed1b1b" />,
        link: "https://twitter.com/ShairDalia",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faFacebook} color="#ed1b1b" />,
        link: "https://www.facebook.com/Daliashaeer",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faGamepad} color="#ed1b1b" />,
        link: "https://lol.fandom.com/wiki/LDASl?fbclid=IwAR1rrHexSocsQcS-Y6jGteOfg13iB9O1RDOABmfI5Pu26l6w0phYuZvMjWI",
      },
    ],
    description:
      "I have been playing League of Legends since 2017, my main role is a Midlaner, the highest rank I reached was Master.<br><br>I enjoy being competitive in every aspect of my life, eventually The best competition is where you compete against yourself to do better than what you’ve done previously and be the best version of yourself. This always keeps me motivated and focused on learning new experiences !",
    img: UnknownPlayerImg,
    logo: LolLogo,
  },
  {
    nickname: "Jaysa",
    name: "Sirine Baccouch",
    country: "Tunisia",
    social: [
      {
        icon: <CustomFontAwesomeIcon icon={faTwitter} color="#ed1b1b" />,
        link: "https://twitter.com/Jaysa_lol",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faTwitch} color="#ed1b1b" />,
        link: "https://www.twitch.tv/jaysaah",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faInstagram} color="#ed1b1b" />,
        link: "https://www.instagram.com/sirine_baccouch/",
      },
    ],
    description:"I spend most of my time playing videos games, I barely touch grass. But that's worth is as I try my best to get a trophy oneday. I joined competitive when I first tried playing a tournament with friends who invited me, we got 2nd place but I enjoyed the feeling of competition and tryhard to win.<br><br>The highest rank I reached in League of Legends as a Support was Diamond 1 and as a ADC was Diamond 4.",
    img: JaysaImg,
    logo: LolLogo,
  },
  {
    nickname: "Wasroket",
    name: "Wassila Louahala",
    country: "Algeria",
    social: [
      {
        icon: <CustomFontAwesomeIcon icon={faTwitter} color="#ed1b1b" />,
        link: "https://twitter.com/wasroket",
      },
      {
        icon: <CustomFontAwesomeIcon icon={faInstagram} color="#ed1b1b" />,
        link: "https://www.instagram.com/wasroket/",
      },
    ],
    description:
      "I'm an IT engineer on an exhilarating quest to become a data scientist extraordinaire. Once I've conquered the world of data, I'll be channeling my inner scientist and diving headfirst into League of Legends, unleashing the power of analytics to dominate the game with flair and passion!<br><br>The highest rank I reached in League of Legend was  Diamond 1 as a Jungler.",
    img: UnknownPlayerImg,
    logo: LolLogo,
  },
];
// const apexPlayers = [
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: ApexLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: ApexLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: ApexLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     img: MplayerImg,
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     logo: ApexLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: ApexLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     country: "Ukraine",
//     img: GplayerImg,
//     logo: ApexLogo,
//   },
// ];
// const tekkenPlayers = [
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: TekkenLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: GplayerImg,
//     logo: TekkenLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: TekkenLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     img: MplayerImg,
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     logo: TekkenLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     country: "Ukraine",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     img: MplayerImg,
//     logo: TekkenLogo,
//   },
//   {
//     nickname: "W1nner",
//     name: "Alex Vaganov",
//     age: "25 years",
//     description:
//       "A professional League of legends player, who started playing League of Legends since 2009. He won a total of 20 tournaments with a total prize pool of around 500,000 DHS.",
//     country: "Ukraine",
//     img: GplayerImg,
//     logo: TekkenLogo,
//   },
// ];

const EsportsPage = ({ setSelected }) => {
  const [display, setDisplay] = useState(false);
  //   const [display1, setDisplay1] = useState(false);
  //   const [display2, setDisplay2] = useState(false);
  //   const [display3, setDisplay3] = useState(false);
//   const [logo, setLogo] = useState(false);
  const [index, setIndex] = useState(0);
  const [players, setPlayers] = useState(lolPlayers);
  const [showModal, setShowModal] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const EsportsSec3Top = document
      .getElementById("EsportsSec3")
      .getBoundingClientRect().top;
    // const EsportsSec4Top = document
    //   .getElementById("EsportsSec4")
    //   .getBoundingClientRect().top;
    // const EsportsSec5Top = document
    //   .getElementById("EsportsSec5")
    //   .getBoundingClientRect().top;
    // const EsportsSec6Top = document
    //   .getElementById("EsportsSec6")
    //   .getBoundingClientRect().top;
    // const EsportsSec7Top = document
    //   .getElementById("EsportsSec7")
    //   .getBoundingClientRect().top;
    if (EsportsSec3Top <= windowHeight - 350) {
      setDisplay(true);
    }
    // if (EsportsSec4Top <= windowHeight - 300) {
    //   setLogo(true);
    // }
    // if (EsportsSec5Top <= windowHeight - 550) {
    //   setDisplay1(true);
    // }
    // if (EsportsSec6Top <= windowHeight - 550) {
    //   setDisplay2(true);
    // }
    // if (EsportsSec7Top <= windowHeight - 550) {
    //   setDisplay3(true);
    // }
  };
  useEffect(() => {
    setSelected(4);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <div style={{ overflow: "hidden" }}>
      <EsportsModal
        showModal={showModal}
        setShowModal={setShowModal}
        players={players}
        index={index}
      />
      <EsportsParallaxBanner />
      <EsportsDescription />
      <Sec2Container style={{ paddingBottom: "150px" }} id="EsportsSec3">
        <EsportsPlayers
          players={lolPlayers}
          setShowModal={setShowModal}
          setPlayers={setPlayers}
          setIndex={setIndex}
          display={display}
          color="rgb(242,205,99)"
          gradient="27, 28, 28"
          left={TechLolLeft}
          right={TechLolLeft}
        />
      </Sec2Container>
      <ClipPath id="EsportsSec5">
        <ComingSoonEsports />
        {/* <EsportsPlayers
          players={valPlayers}
          setShowModal={setShowModal}
          setPlayers={setPlayers}
          setIndex={setIndex}
          display={display1}
          color="rgb(254,70,85)"
          gradient="239,244,243"
          left={TechValLeft}
          right={TechValLeft}
        /> */}
      </ClipPath>
      {/* <Sec2Container style={{ paddingBottom: "150px" }} id="EsportsSec6">
        <EsportsPlayers
          players={tekkenPlayers}
          setShowModal={setShowModal}
          setPlayers={setPlayers}
          setIndex={setIndex}
          display={display2}
          color="rgb(254,84,22)"
          gradient="27, 28, 28"
          left={TechTekken}
          right={TechTekken}
        />
      </Sec2Container>
      <ClipPath id="EsportsSec7">
        <EsportsPlayers
          players={apexPlayers}
          setShowModal={setShowModal}
          setPlayers={setPlayers}
          setIndex={setIndex}
          display={display3}
          color="rgb(255,58,57)"
          gradient="239,244,243"
          left={TechApex}
          right={TechApex}
        />
      </ClipPath> */}
    </div>
  );
};

const Sec2Container = styled.div`
  padding-top: 150px;
  position: relative;
`;

// const WeText = styled.h1`
//   transition: all 0.7s;
//   font-size: 80px;
//   text-transform: uppercase;
//   font-family: Rubik;
//   font-weight: 700;
//   z-index: 90;
//   color: var(--text-1-500);
//   position: absolute;
//   @media (max-width: 1500px) {
//     font-size: 70px;
//   }
//   @media (max-width: 1024px) {
//     font-size: 50px;
//   }
//   @media (max-width: 767px) {
//     font-size: 35px;
//   }
//   @media (max-width: 500px) {
//     font-size: 25px;
//   }
// `;
// const EsportsContainer = styled.div`
//   display: flex;
//   transform: translateZ(0);
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   width: 100%;
//   height: 300px;
//   @media (max-width: 1500px) {
//     height: 250px;
//   }
//   @media (max-width: 1024px) {
//     height: 170px;
//   }
//   @media (max-width: 767px) {
//     height: 120px;
//   }
//   @media (max-width: 500px) {
//     height: 70px;
//   }
// `;

// const BarzahBorder = styled.h2`
//   transition: all 0.7s;
//   font-size: 250px;
//   text-transform: uppercase;
//   font-family: Rubik;
//   text-align: center;
//   z-index: 11;
//   letter-spacing: 3px;
//   font-weight: 700;
//   position: absolute;
//   color: rgba(255, 255, 255, 0);
//   visibility: visible;
//   border-top-left-radius: 1px;
//   @media (max-width: 1500px) {
//     font-size: 200px;
//   }
//   @media (max-width: 1024px) {
//     font-size: 150px;
//   }
//   @media (max-width: 767px) {
//     font-size: 100px;
//   }
//   @media (max-width: 500px) {
//     font-size: 70px;
//   }
// `;

const ClipPath = styled.div`
  background: var(--main-2-500);
  z-index: 10;
  position: relative;
`;

export default EsportsPage;
