import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NewsCard from "../newsCard";

const NewsClipArt = () => {
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const NewsSec2Top = document
      .getElementById("NewsSec2")
      .getBoundingClientRect().top;
    if (NewsSec2Top <= windowHeight - 300) {
      setLogo(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1670) {
      setLogo(true);
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setLogo]);
  return (
    <>
      <GameDev id="NewsSec2">
        <DoubleText
          style={{
            zIndex: 9,
            webkitTextStroke: "1px rgb(255, 255, 255)",
            bottom: logo ? "40px" : "-20%",
            opacity: logo ? 1 : 0,
          }}
        >
          News
        </DoubleText>
      </GameDev>
      <ClipPath>
        <GameDev>
          <DoubleText
            style={{
              webkitTextStroke: "1px rgb(0, 0, 0)",
              bottom: logo ? "40px" : "-20%",
              opacity: logo ? 1 : 0,
            }}
          >
            News
          </DoubleText>
        </GameDev>
        <NewsContainer>
          <NewsCard />
        </NewsContainer>
      </ClipPath>
    </>
  );
};

const GameDev = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 300px;
  @media (max-width: 1500px) {
    height: 250px;
  }
  @media (max-width: 1024px) {
    height: 170px;
  }
  @media (max-width: 767px) {
    height: 130px;
  }
  @media (max-width: 500px) {
    height: 90px;
  }
`;

const DoubleText = styled.h2`
  transition: all 0.7s;
  @media (max-width: 1670px) {
    transition: none;
  }
  font-size: 250px;
  text-transform: uppercase;
  font-family: sans-serif;
  text-align: center;
  z-index: 11;
  letter-spacing: 3px;
  font-weight: 600;
  position: absolute;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1500px) {
    font-size: 180px;
  }
  @media (max-width: 1024px) {
    font-size: 130px;
  }
  @media (max-width: 767px) {
    font-size: 80px;
  }
  @media (max-width: 500px) {
    font-size: 45px;
  }
`;

const ClipPath = styled.div`
  background: var(--text-1-500);
  z-index: 10;
  height: 100%;
  position: relative;
  clip-path: polygon(0 250px, 100% 0, 100% 100%, 0% 100%);
  @media (max-width: 1500px) {
    clip-path: polygon(0 200px, 100% 0, 100% 100%, 0% 100%);
  }
  @media (max-width: 1024px) {
    clip-path: polygon(0 120px, 100% 0, 100% 100%, 0% 100%);
  }
  @media (max-width: 767px) {
    clip-path: polygon(0 90px, 100% 0, 100% 100%, 0% 100%);
  }
  @media (max-width: 500px) {
    clip-path: polygon(0 50px, 100% 0, 100% 100%, 0% 100%);
  }
`;

const NewsContainer = styled.div`
  height: 100%;
  transform: translateZ(0);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 340px 50px 0px 50px;
  @media (max-width: 1500px) {
    padding: 280px 50px 0px 50px;
  }
  @media (max-width: 1024px) {
    padding: 180px 50px 0px 50px;
  }
  @media (max-width: 767px) {
    padding: 140px 20px 0px 20px;
  }
  @media (max-width: 500px) {
    padding: 80px 20px 0px 20px;
  }
  background-color: #eff4f3;
`;

export default NewsClipArt;
