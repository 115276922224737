import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ContactModal from "../contactModal";
import { Navbar } from "../navBar";
import { Footer } from "./footer";

export function Layout({ selected, setShowModal, showModal }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div>
      <Navbar selected={selected} setShowModal={setShowModal} />
      <ContactModal showModal={showModal} setShowModal={setShowModal} />
      <Outlet />
      <Footer />
    </div>
  );
}
