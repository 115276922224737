import React, { useState } from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import axiosPrivate from "../../axios";
import ReCAPTCHA from "react-google-recaptcha";
import { AnimatePresence, motion } from "framer-motion";

export const SendEmail = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);

  const sendEmail = async (e) => {
    setError(null);
    try {
      await axiosPrivate.post("/send-email", e);
      setSent(true);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <FormContainer id="TrainingSec4">
      <SecTitle>ENROLL FOR UE5 TRAINING COURSE</SecTitle>
      <AnimatePresence>
        {sent ? (
          <motion.div
            style={{
              height: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormTitle
              initial={{ opacity: 0, x: 1000 }}
              animate={{ opacity: 1, x: 0 }}
              key={1}
              transition={{ duration: 0.5, delay: 0.2 }}
              style={{
                WebkitTextStroke: "1px rgb(255, 255, 255)",
                position: "absolute",
              }}
            >
              <span style={{ color: "var(--text-1-500)" }}>Stay </span> in touch
            </FormTitle>
          </motion.div>
        ) : (
          <FormWrapper
            initial={{ opacity: 1 }}
            key={2}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CustomForm onSubmit={handleSubmit(sendEmail)}>
              <SecTitle2>ENROLL FOR UE5 TRAINNING COURSE</SecTitle2>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>First name</Form.Label>
                <CustomFormControl
                  style={{
                    background: "none",
                    borderRadius: 0,
                  }}
                  {...register("firstName", {
                    required: "Your first name is required",
                    maxLength: 100,
                  })}
                  isInvalid={errors?.firstName}
                  type="text"
                  placeholder="Enter first name..."
                />
                {errors.firstName && (
                  <h1 style={{ color: "#ff7f7f", paddingTop: "5px" }}>
                    {errors?.firstName?.message}
                  </h1>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last name</Form.Label>
                <CustomFormControl
                  style={{
                    background: "none",
                    borderRadius: 0,
                  }}
                  {...register("lastName", {
                    required: "Your last name is required",
                    maxLength: 100,
                  })}
                  isInvalid={errors?.lastName}
                  type="text"
                  placeholder="Enter last name..."
                />
                {errors.lastName && (
                  <h1 style={{ color: "#ff7f7f", paddingTop: "5px" }}>
                    {errors?.lastName?.message}
                  </h1>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <CustomFormControl
                  style={{
                    background: "none",
                    borderRadius: 0,
                  }}
                  {...register("email", {
                    required: "Your email address is required",
                    maxLength: 100,
                  })}
                  isInvalid={errors?.email}
                  type="text"
                  placeholder="Enter email..."
                />
                {errors.email && (
                  <h1 style={{ color: "#ff7f7f", paddingTop: "5px" }}>
                    {errors?.email?.message}
                  </h1>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone number</Form.Label>
                <CustomFormControl
                  style={{
                    background: "none",
                    borderRadius: 0,
                  }}
                  {...register("phoneNumber", {
                    required: "Your phone number is required",
                    maxLength: 100,
                  })}
                  isInvalid={errors?.phoneNumber}
                  placeholder="Enter phone number..."
                />
                {errors.phoneNumber && (
                  <h1 style={{ color: "#ff7f7f", paddingTop: "5px" }}>
                    {errors?.phoneNumber?.message}
                  </h1>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Your comment</Form.Label>
                <CustomTextArea
                  className="form-control"
                  style={{
                    background: "none",
                    borderRadius: 0,
                  }}
                  as="textarea"
                  maxLength={100}
                  showCount
                  rows={5}
                  {...register("comment")}
                  isInvalid={errors?.phoneNumber}
                  placeholder="Enter comment..."
                />
              </Form.Group>
              <Controller
                control={control}
                name="captcha"
                render={({ field: { onChange } }) => (
                  <ReCAPTCHA
                    sitekey="6LfS4BMkAAAAACyfn5IndultQbt5hnCiLd5vkfkW"
                    style={{ paddingBottom: "20px" }}
                    onChange={onChange}
                  />
                )}
              />
              {error && (
                <h1 style={{ color: "#ff7f7f", paddingBottom: "15px" }}>
                  Please solve the captcha
                </h1>
              )}
              <CustomButton variant="primary" type="htmlSubmit">
                Submit
              </CustomButton>
            </CustomForm>
            {/* <TimingComp /> */}
          </FormWrapper>
        )}
      </AnimatePresence>
    </FormContainer>
  );
};

const FormTitle = styled(motion.h2)`
  font-size: 40px;
  text-transform: uppercase;
  font-family: Rubik;
  text-align: center;
  z-index: 11;
  width: 600px;
  letter-spacing: 3px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0);
  visibility: visible;
  border-top-left-radius: 1px;
  @media (max-width: 1350px) {
    font-size: 30px;
    width: 400px;
  }
  @media (max-width: 1100px) {
    font-size: 20px;
    width: 350px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    width: 400px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
    width: 350px;
  }
`;

const SecTitle2 = styled.h1`
  font-size: 50px;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Rubik";
  font-weight: 700;
  @media (min-width: 1281px) {
    display: none;
  }
  @media (max-width: 600px) {
    padding: 0;
    font-size: 30px;
  }
`;

const CustomForm = styled(Form)`
  max-width: 500px;
  width: 100%;
  font-family: "Space Mono";
`;

const CustomTextArea = styled(Form.Control)`
  color: var(--text-1-500);
  &:focus {
    color: var(--text-1-500);
  }
  &::placeholder {
    color: var(--text-3-500) !important;
    opacity: 1;
  }
`;

const CustomButton = styled.button`
  color: var(--text-1-500);
  background-color: transparent;
  border: 1px solid var(--text-1-500);
  width: 100%;
  padding: 10px 20px 12px calc(20px - 0.02em);
  justify-content: center;
  text-align: center;
  font-size: 17px;
  letter-spacing: 0.02em;
  font-family: Rubik, sans-serif;
  font-weight: 700;
  line-height: calc(30 / 17);
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  isolation: isolate;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  &:hover::before {
    clip-path: inset(0 0 0 0);
  }
  &::after {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: 5px;
    background-color: currentColor;
    position: absolute;
    bottom: 3px;
    left: 3px;
    pointer-events: none;
  }
  &::before {
    content: "";
    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px - 4px);
    background-color: currentColor;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    transition: clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96),
      -webkit-clip-path 0.6s cubic-bezier(0.45, 0.2, 0.01, 0.96);
    pointer-events: none;
    mix-blend-mode: difference;
    -webkit-clip-path: inset(100% 0 0 0);
    clip-path: inset(100% 0 0 0);
  }
`;

const FormWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  padding: 50px;
  gap: 200px;
  justify-content: center;
  @media (max-width: 1280px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 100px;
  }
  @media (max-width: 767px) {
    padding: 30px;
  }
`;

const CustomFormControl = styled(Form.Control)`
  color: var(--text-1-500);
  font-family: "Space Mono";
  &:focus {
    color: var(--text-1-500);
  }
  &::placeholder {
    color: var(--text-3-500) !important;
    opacity: 1;
  }
`;

const SecTitle = styled.h1`
  font-size: 50px;
  margin-bottom: 40px;
  font-family: Rubik;
  font-weight: 700;
  @media (max-width: 1280px) {
    display: none;
  }
  @media (max-width: 600px) {
    padding: 0;
    font-size: 30px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  color: var(--text-1-500);
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: var(--main-600);
`;
