import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { ContactEmail } from "../contactEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { ArrowsBlack, ArrowsWhite, LinesBlack, LinesWhite } from "../../assets";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

const modal = {
  hidden: { height: "0", transition: { duration: 0.5 } },
  visible: {
    height: "92%",
    transition: { duration: 0.5 },
  },
};

const ContactModal = ({ showModal, setShowModal }) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }
  }, [showModal]);

  const handleClick = (e) => {
    const element = document.getElementById("modal-content");
    if (!element.contains(e.target)) {
      setShowModal(false);
    }
  };
  return (
    <AnimatePresence>
      {showModal && (
        <ModalDiv onMouseDown={handleClick}>
          <ModalCard
            id="modal-content"
            variants={modal}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <PlayerCardCont>
              <CloseBtn icon={faClose} onClick={() => setShowModal(false)} />
              <ClipArt>
                <ArrowImg src={ArrowsBlack} />
                <LinesImg src={LinesBlack} />
                <ContactEmail />
              </ClipArt>
              <ContactInfo>
                <ArrowImg2 src={ArrowsWhite} />
                <LinesImg2 src={LinesWhite} />
                <TitleWrapper>
                  <BarzahSolid>Barzah</BarzahSolid>
                  <StudioSolid>Studio</StudioSolid>
                </TitleWrapper>
                <LocationText>
                  <CustomFontAwesome icon={faMapMarkerAlt} />
                  <h1>Abu Dhabi, Yas creative hub</h1>
                </LocationText>
                <SocialWrapper>
                  <a
                    href="https://twitter.com/Barzahstudio"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialImg icon={faTwitter} color="white" />
                  </a>
                  <a
                    href="https://www.instagram.com/barzahstudio/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SocialImg icon={faInstagram} color="white" />
                  </a>
                </SocialWrapper>
              </ContactInfo>
            </PlayerCardCont>
          </ModalCard>
        </ModalDiv>
      )}
    </AnimatePresence>
  );
};

const ArrowImg2 = styled.img`
  position: absolute;
  left: 50px;
  width: 60px;
  rotate: 180deg;
  bottom: 50px;
  @media (max-width: 900px) {
    bottom: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const LinesImg2 = styled.img`
  position: absolute;
  right: 40px;
  top: 50px;
  width: 20px;
  @media (max-width: 900px) {
    right: 50px;
    width: 20px;
    top: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const LinesImg = styled.img`
  position: absolute;
  left: 120px;
  bottom: 0px;
  rotate: 90deg;
  width: 30px;
  @media (max-width: 900px) {
    left: 100px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-height: 850px) {
    bottom: -30px;
  }
  @media (max-height: 770px) {
    display: none;
  }
`;

const ArrowImg = styled.img`
  position: absolute;
  right: 100px;
  width: 20px;
  rotate: 90deg;
  top: 50px;
  @media (max-width: 900px) {
    top: 20px;
  }
  @media (max-width: 1240px) {
    display: none;
  }
  @media (max-height: 800px) {
    top: 0px;
  }
  @media (max-width: 660px) {
    display: none;
  }
`;

const CustomFontAwesome = styled(FontAwesomeIcon)`
  font-size: 18px;
  @media (max-width: 900px) {
    font-size: 13px;
  }
  @media (max-width: 600px) {
    font-size: 7px;
  }
`;

const LocationText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Space Mono";
  gap: 10px;
  @media (max-width: 900px) {
    font-size: 11px;
  }
  @media (max-width: 600px) {
    font-size: 8px;
    width: 150px;
  }
`;

const SocialImg = styled(FontAwesomeIcon)`
  font-size: 50px;
  width: 60px;
  @media (max-width: 900px) {
    font-size: 30px;
    width: 40px;
  }
  @media (max-width: 600px) {
    font-size: 20px;
    width: 30px;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  width: 530px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 230px;
  }
`;

const StudioSolid = styled.h2`
  color: var(--text-1-500);
  font-size: 15px;
  rotate: -90deg;
  text-transform: uppercase;
  font-family: "Rubik";
  text-align: center;
  letter-spacing: 3px;
  font-weight: 700;
  position: absolute;
  right: 0;
  margin-bottom: 7px;
  border-top-left-radius: 1px;
  @media (max-width: 1024px) {
    font-size: 4px;
    margin-bottom: 5px;
  }
`;

const BarzahSolid = styled.h2`
  color: var(--text-1-500);
  font-size: 100px;
  text-transform: uppercase;
  font-family: "Rubik";
  text-align: center;
  letter-spacing: 3px;
  z-index: 42;
  font-weight: 700;
  border-top-left-radius: 1px;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  color: var(--text-1-500);
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  gap: 20px;
  justify-content: center;
  @media (max-width: 900px) {
    gap: 6px;
  }
`;

const ClipArt = styled.div`
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  background: var(--text-1-500);
  padding-right: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    clip-path: none;
    padding-right: 0;
    padding-bottom: 100px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
`;

const PlayerCardCont = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--main-600);
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ModalDiv = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const ModalCard = styled(motion.div)`
  background-color: #fefefe;
  margin: 15% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border: 1px solid #888;
  width: 94%;
`;

const CloseBtn = styled(FontAwesomeIcon)`
  color: #858484;
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 25px;
  z-index: 99999;
  &:hover {
    color: var(--text-1-500);
    cursor: pointer;
    @media (max-width: 900px) {
      color: var(--text-2-500);
    }
  }
`;

export default ContactModal;
