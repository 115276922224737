import React from "react";
import styled from "styled-components";
import ModalPlayerLines from "./modalPlayerLines";

const ModalPlayerCard = ({ img, color, gradient }) => {
  return (
    <PlayerCardCont>
      <PlayerWrapper color={color}>
        <PlayerImg src={img} />
        <ImageGradient gradient={gradient} />
        <LineDiv>
          <ModalPlayerLines
            display={true}
            leftLine={false}
            left={200}
            top={210}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={false}
            left={200}
            top={150}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={false}
            left={150}
            top={150}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={false}
            left={120}
            top={120}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={true}
            left={120}
            top={230}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={true}
            left={120}
            top={170}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={true}
            left={140}
            top={140}
            color={color}
          />
          <ModalPlayerLines
            display={true}
            leftLine={true}
            left={180}
            top={120}
            color={color}
          />
        </LineDiv>
      </PlayerWrapper>
    </PlayerCardCont>
  );
};

const PlayerCardCont = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  /* max-width: 450px;
  max-height: 450px;
  @media (max-width: 767px)
  {
	max-width: 300px;
	max-height: 300px;
  } */
`;

const ImageGradient = styled.div`
  width: 100%;
  height: 50%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  background-image: ${(props) =>
    `linear-gradient(rgba(${props.gradient}, 0), rgba(${props.gradient}, 1))`};
`;

const LineDiv = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
`;

const PlayerWrapper = styled.div`
  /* width: 450px;
  height: 450px; */
  height: 300px;
  width: 300px;
  /* @media (max-width: 767px) {
    width: 300px;
    height: 300px;
  } */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: border 0.7s;
  border: ${(props) => `1px solid ${props.color}`};
`;

const PlayerImg = styled.img`
  /* height: 535px; */
  height: 340px;
  /* @media (max-width: 767px) {
    height: 340px;
  } */
  bottom: 0px;
  z-index: 9;
  position: absolute;
`;

export default ModalPlayerCard;
