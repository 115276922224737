import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  HomeCorousel1,
  HomeCorousel2,
  HomeCorousel5,
  HomeCorousel4,
} from "../../assets";

const imgSlide = [HomeCorousel1, HomeCorousel2, HomeCorousel5, HomeCorousel4];

const HomeCarousel = () => {
  return (
    <CarouselDiv id="HomeSec4">
      <SlideContainer>
        <Swiper
          style={{ height: "auto" }}
          allowTouchMove={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={1500}
          navigation={true}
          centeredSlides={true}
          loop={true}
          pagination={{ enabled: false }}
          breakpoints={{
            1024: {
              pagination: { enabled: false },
              slidesPerView: 2,
              spaceBetween: 30,
            },
            720: {
              pagination: { enabled: false },
              slidesPerView: 2,
              spaceBetween: 30,
            },
            540: {
              pagination: { enabled: true },
              slidesPerView: 1,
            },
            320: {
              pagination: { enabled: true },
              slidesPerView: 1,
            },
          }}
        >
          {imgSlide.map((slideContent) => (
            <CustomSwiperSlidePic key={slideContent}>
              <img src={slideContent} alt="slider images" />
            </CustomSwiperSlidePic>
          ))}
        </Swiper>
      </SlideContainer>
    </CarouselDiv>
  );
};

const SlideContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const CustomSwiperSlidePic = styled(SwiperSlide)`
  & img {
    transition: all 1.5s;
    opacity: 0.5;
  }

  background: var(--main-600);
  &.swiper-slide-prev img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-active img {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-next img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-duplicate-prev img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
  &.swiper-slide-duplicate-active img {
    opacity: 1;
    transition: all 1.5s;
  }
  &.swiper-slide-duplicate-next img {
    opacity: 0.5;
    transition: all 1.5s;
    filter: blur(3px);
  }
`;

const CarouselDiv = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(
    to bottom,
    var(--main-2-500) 50%,
    var(--main-600) 50%
  );
  margin-top: -1px;
  font-family: "Space Mono";
`;

export default HomeCarousel;
