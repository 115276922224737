import { ParallaxBanner } from "react-scroll-parallax";
import { JLetterImg, StudioMainImg } from "../../../assets";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Lottie from "lottie-react";
import BorderAnim from "./animation_lehalp5z.json";
import { AnimatePresence, motion } from "framer-motion";

export function StudioParallaxBanner() {
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  //   const [showParallax, setShowParallax] = useState(true);
  //   const img1Animation = useAnimation();

  //   const handleMouseMove = (e) => {
  //     const { clientX, clientY } = e;
  //     const moveX = clientX - window.innerWidth / 2;
  //     const moveY = clientY - window.innerHeight / 2;
  //     const offsetFactor = 15;
  //     img1Animation.start({
  //       x: moveX / -offsetFactor,
  //       y: moveY / offsetFactor,
  //     });
  //   };

  const handleScroll = () => {
    // const windowHeight = window.innerHeight;
    // const EsportsSec2Top = document
    //   .getElementById("StudioSec2")
    //   .getBoundingClientRect().top;
    // const EsportsSec3Top = document
    //   .getElementById("StudioSec3")
    //   .getBoundingClientRect().top;
    // if (EsportsSec2Top <= windowHeight - 350) {
    //   setDisplay(false);
    // } else {
    //   setDisplay(true);
    // }
    // if (EsportsSec3Top <= windowHeight - 500) {
    //   setShowParallax(false);
    // } else {
    //   setShowParallax(true);
    // }
  };
  useEffect(() => {
    setDisplay(true);
    var bgImg = new Image();
    bgImg.onload = function () {
      document.getElementById("background-image").style.backgroundImage =
        "url(" + bgImg.src + ")";
      setLoading(false);
    };
    bgImg.src = StudioMainImg;
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const textFront = {
    translateY: [0, -100],
    speed: -10,
    scale: [1, 1.2],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="center">
        {/*  Border & Solid */}
        <TitleWrapper>
          <CustomLottie animationData={BorderAnim} loop={false} />
          <AnimatePresence>
            {display && !loading && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.9 }}
                style={{ color: "var(--text-1-500)" }}
              >
                <JLetterImage src={JLetterImg} />
              </motion.span>
            )}
          </AnimatePresence>
        </TitleWrapper>
      </div>
    ),
  };
  return (
    <ParallaxContainer
      id="background-image"
      style={{
        opacity: display ? 1 : 0,
      }}
    >
      <BackgroundGrad />
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          <Spinner animation="border" variant="light"></Spinner>
        </div>
      )}
      <AnimationDiv
        style={{
          display: !loading ? "flex" : "none",
        }}
      >
        {/* <ImgContainer>
          <GamersGroupCom animate={img1Animation} src={GamersGroupImg} alt="" />
        </ImgContainer> */}
        {display && !loading && <CustomParallaxBanner layers={[textFront]} />}
        <FontAwesomeIcon
          style={{
            color: "#fff",
            position: "absolute",
            bottom: "50px",
            zIndex: 9999,
          }}
          icon={faAnglesDown}
          size="2x"
        />
      </AnimationDiv>
    </ParallaxContainer>
  );
}

const JLetterImage = styled.img`
  height: 140px;
  @media (max-height: 1000px) {
    height: 120px;
  }
  @media (max-width: 1500px) {
    height: 120px;
  }
  @media (max-height: 700px) {
    height: 90px;
  }
  @media (max-width: 1024px) {
    height: 90px;
  }
  @media (max-width: 767px) {
    height: 70px;
  }
  @media (max-width: 600px) {
    height: 47px;
  }
  @media (max-width: 420px) {
    height: 37px;
  }
`;

const CustomLottie = styled(Lottie)`
  width: 1000px;
  @media (max-height: 1000px) {
    width: 800px;
  }
  @media (max-width: 1500px) {
    width: 800px;
  }
  @media (max-height: 700px) {
    width: 600px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 600px) {
    width: 320px;
  }
  @media (max-width: 420px) {
    width: 250px;
  }
`;

const BackgroundGrad = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
  position: absolute;
  background-image: linear-gradient(rgba(27, 28, 28, 0), rgba(27, 28, 28, 1));
`;

const ParallaxContainer = styled.div`
  transition: all 0.5s;
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AnimationDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 1040px;
  height: 100%;
  align-items: center;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 1500px) {
    width: 1040px;
    gap: 30px;
  }
  @media (max-width: 1024px) {
    width: 790px;
    gap: 20px;
  }
  @media (max-width: 767px) {
    width: 540px;
    gap: 15px;
  }
  @media (max-width: 600px) {
    width: 500px;
  }
  @media (max-width: 420px) {
    gap: 10px;
  }
`;

const CustomParallaxBanner = styled(ParallaxBanner)`
  [data-testid="layer-0"] {
    z-index: 10;
  }
  [data-testid="layer-1"] {
    z-index: 15;
  }
  width: 100%;
  height: 100%;
`;
