import { ParallaxBanner } from "react-scroll-parallax";
import {
  BackgroundTraining,
  BarzahEsportsSolidRed,
} from "../../../assets";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { AnimatePresence, useAnimation } from "framer-motion";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import BorderAnim from "./animation_lg2765y2.json";
import Lottie from "lottie-react";

export function EsportsParallaxBanner() {
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showParallax, setShowParallax] = useState(true);
  const img1Animation = useAnimation();

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    const offsetFactor = 15;
    img1Animation.start({
      x: moveX / -offsetFactor,
      y: moveY / offsetFactor,
    });
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const EsportsSec2Top = document
      .getElementById("EsportsSec2")
      .getBoundingClientRect().top;
    const EsportsSec3Top = document
      .getElementById("EsportsSec3")
      .getBoundingClientRect().top;
    if (EsportsSec2Top <= windowHeight - 150) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
    if (EsportsSec3Top <= windowHeight - 500) {
      setShowParallax(false);
    } else {
      setShowParallax(true);
    }
  };
  useEffect(() => {
    setDisplay(true);
    var bgImg = new Image();
    bgImg.onload = function () {
      document.getElementById("background-image").style.backgroundImage =
        "url(" + bgImg.src + ")";
      setLoading(false);
    };
    bgImg.src = BackgroundTraining;
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const textFront = {
    translateY: [0, -100],
    speed: -10,
    scale: [1, 1.2],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <div className="center">
        {/* Border */}
        <TitleWrapper>
          <CustomLottie animationData={BorderAnim} loop={false} />
        </TitleWrapper>
      </div>
    ),
  };
  const textBack = {
    translateY: [0, -100],
    shouldAlwaysCompleteAnimation: true,
    speed: -10,
    scale: [1, 1.2],
    expanded: false,
    children: (
      <div className="center">
        {/* Solid */}
        <TitleWrapper>
          <AnimatePresence>
            {display && !loading && (
              <EsportsSolid
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
                src={BarzahEsportsSolidRed}
              />
            )}
          </AnimatePresence>
        </TitleWrapper>
      </div>
    ),
  };
  return (
    <ParallaxContainer
      onMouseMove={handleMouseMove}
      id="background-image"
      style={{
        opacity: display ? 1 : 0,
      }}
    >
      {showParallax && <BackgroundGrad />}
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
          }}
        >
          <Spinner animation="border" variant="light"></Spinner>
        </div>
      )}
      <AnimationDiv
        style={{
          display: showParallax && !loading ? "flex" : "none",
        }}
      >
        {/* <ImgContainer>
          <GamersGroupCom animate={img1Animation} src={GamersGroupImg} alt="" />
        </ImgContainer> */}
        {display && !loading && (
          <CustomParallaxBanner layers={[textBack, textFront]} />
        )}
        <FontAwesomeIcon
          style={{
            color: "var(--text-1-500)",
            position: "absolute",
            bottom: "50px",
            zIndex: 9999,
          }}
          icon={faAnglesDown}
          size="2x"
        />
      </AnimationDiv>
    </ParallaxContainer>
  );
}

const CustomLottie = styled(Lottie)`
  width: 1000px;
  @media (max-height: 1000px) {
    width: 800px;
  }
  @media (max-width: 1500px) {
    width: 800px;
  }
  @media (max-height: 700px) {
    width: 600px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 528px) {
    width: 320px;
  }
  @media (max-width: 358px) {
    width: 250px;
  }
`;

// const ImgContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   align-items: center;
//   display: flex;
//   justify-content: center;
// `;

const BackgroundGrad = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
  position: absolute;
  background-image: linear-gradient(rgba(27, 28, 28, 0), rgba(27, 28, 28, 1));
`;

// const GamersGroupCom = styled(motion.img)`
//   width: 100%;
//   transition: all 0.1s;
//   z-index: 12;
//   max-width: 1000px;
//   position: fixed;
//   margin: 0 0 480px 0;
//   @media (max-height: 1000px) {
//     max-width: 600px;
//     margin: 0 0 275px 0;
//   }
//   @media (max-width: 1500px) {
//     max-width: 700px;
//     margin: 0 0 375px 0;
//   }
//   @media (max-height: 700px) {
//     max-width: 470px;
//     margin: 0 0 255px 0;
//   }
//   @media (max-width: 1024px) {
//     max-width: 470px;
//     margin: 0 0 255px 0;
//   }
//   @media (max-width: 767px) {
//     max-width: 430px;
//     margin: 0 0 225px 0;
//   }
//   @media (max-width: 500px) {
//     max-width: 300px;
//     margin: 0 0 165px 0;
//   }
// `;

const ParallaxContainer = styled.div`
  transition: all 0.5s;
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AnimationDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 200px;
  @media (max-height: 700px) {
    padding-top: 300px;
  }
  @media (max-height: 1024px) {
    padding-top: 100px;
  }
  justify-content: center;
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 1040px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-height: 1000px) {
    width: 1040px;
  }
  @media (max-width: 1500px) {
    width: 1040px;
  }
  @media (max-height: 700px) {
    width: 790px;
  }
  @media (max-width: 1024px) {
    width: 790px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 500px) {
    width: 1040px;
  }
`;

const EsportsSolid = styled(motion.img)`
  position: absolute;
  width: 1000px;
  @media (max-height: 1000px) {
    width: 800px;
  }
  @media (max-width: 1500px) {
    width: 800px;
  }
  @media (max-height: 700px) {
    width: 600px;
  }
  @media (max-width: 1024px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    width: 500px;
  }
  @media (max-width: 528px) {
    width: 320px;
  }
  @media (max-width: 358px) {
    width: 250px;
  }
`;

const CustomParallaxBanner = styled(ParallaxBanner)`
  [data-testid="layer-0"] {
    z-index: 10;
  }
  [data-testid="layer-1"] {
    z-index: 15;
  }
  width: 100%;
  height: 100%;
`;
